import React, { useContext } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';

import { AppContext } from '../contexts/AppProvider.jsx';
import { TasksContext } from '../contexts/TasksProvider.jsx';

function MenuTasks() {

    const { appTheme } = useContext(AppContext);
    const { 
        tasksViewArchived, setTasksViewArchived,
        showedMenuTasks, setShowedMenuTasks 
    } = useContext(TasksContext);
    return (
        <Offcanvas show={showedMenuTasks} onHide={() => setShowedMenuTasks(false)} placement="end" className={appTheme === "dark" ? 'bg-black text-white' : 'bg-white text-black'}>
            <Offcanvas.Header closeButton closeVariant={appTheme === "dark" ? "white" : ""}>
                <Offcanvas.Title>Tâches</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                
                <Form.Check 
                    type="switch"
                    id="custom-switch"
                    label="Afficher les tâches archivées"
                    onChange={() => setTasksViewArchived(!tasksViewArchived)}
                    checked={tasksViewArchived}
                />
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default MenuTasks;