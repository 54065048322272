
import React, { useContext } from 'react';

import Icon from './Icon.jsx';
import BasicTooltip from './Tooltip.jsx';

function ButtonIcon({name, textColor, className, tooltipContent, tooltipPlacement, onClick}) {

    if(!textColor) {
        textColor = "primary";
    }

    if(tooltipContent && !tooltipPlacement) {
        tooltipPlacement = "bottom";
    }

    if(!tooltipContent) {
        return (<>
            <span className={`cursor-${onClick ? 'pointer' : 'default'} btn-icon text-${textColor}${className ? ` ${className}` : ''}`} onClick={onClick}>
                <Icon name={name} />
            </span>
        </>);
    }

    return (<>
        <span className={`cursor-${onClick ? 'pointer' : 'help'} btn-icon text-${textColor}${className ? ` ${className}` : ''}`} onClick={onClick}>
            <BasicTooltip content={tooltipContent} placement={tooltipPlacement}>
                <Icon name={name} />
            </BasicTooltip>
        </span>
    </>);
  
}

export default ButtonIcon;


