import React, { useState, useLayoutEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { createSubtask, fetchTasks } from '../services/tasks';
import { setColors } from '../libs/utils';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { AppContext } from '../contexts/AppProvider';
import { TasksContext } from '../contexts/TasksProvider';

import Icon from '../ui/Icon';
import { useEffect } from 'react';

function ModalSubtask() {

  const { t } = useTranslation();

  const {
    appTheme,
    lastAction, setLastAction
  } = useContext(AppContext);

  const {
    showModalSubtask, setShowModalSubtask, editedTask, setTasks
  } = useContext(TasksContext);

  const [subtaskTitle, setSubtaskTitle] = useState("");


  const handleSubmit = (event) => {
    event.preventDefault();
    // setLastAction("");

    const subtaskData = {
      subtask_title: subtaskTitle
    }

    if (editedTask) {
      createSubtask(editedTask, subtaskData).then((response) => {
        if (response.status === "Created") {
          console.log("✅ Subtask created");
          setShowModalSubtask(false);
          setLastAction([...lastAction, "create_subtask"]);
          // setLastAction("create_subtask");
          fetchTasks().then((response) => {
            setTasks(response.result);
          });
        }
      });
    }
  }


  useLayoutEffect(() => {
    setColors();
  });

  useEffect(() => {
    setSubtaskTitle("");
  }, [showModalSubtask])

  return (
    <>

      <Modal show={showModalSubtask} onHide={() => setShowModalSubtask(false)} centered>

        <Modal.Header closeButton>
          <Modal.Title className={`text-${appTheme === "dark" ? "black" : 'white'}`}>{t('create_subtask')}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body className={`bg-${appTheme === "dark" ? 'black' : 'white'} text-${appTheme !== "dark" ? 'black' : 'white'}`}>

            <Form.Group className="mb-3">
              <Form.Label>Titre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez le titre de la sous-tâche"
                value={subtaskTitle}
                onChange={(event) => setSubtaskTitle(event.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className={`bg-${appTheme === "dark" ? 'dark' : 'white'} border-${appTheme === "dark" ? 'black' : 'gray-300'}`} style={{ borderTopWidth: 1, borderTopStyle: "solid" }}>
            <div className="d-grid w-100">
              <Button type="submit" variant="primary" size="lg">
                <Icon name="check" className="me-2" />{t('add')}
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default ModalSubtask;