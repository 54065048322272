import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";

import { formatDuration, formatDate } from "../libs/utils";

import { AppContext } from '../contexts/AppProvider';
import { TasksContext } from "../contexts/TasksProvider";

import { fetchTask } from '../services/tasks';

import Icon from "../ui/Icon";



function ModalTaskStats() {

    const { t } = useTranslation();
    const { appTheme } = useContext(AppContext);

    const {
        showModalTaskStats, setShowModalTaskStats, showedTask
    } = useContext(TasksContext);

    const [task, setTask] = useState({})
    const [totalTimingsDuration, setTotalTimingsDuration] = useState(0);
    const [subtasksTimingsDuration, setSubtasksTimingsDuration] = useState([]);

    const statusOptions = [
        { value: 'todo', label: t('task_status_to_do'), icon: 'calendar-day' },
        { value: 'progress', label: t('task_status_in_progress'), icon: 'hourglass-clock' },
        { value: 'done', label: t('task_status_done'), icon: 'flag-checkered' },
    ];

    useEffect(() => {
        fetchTask(showedTask).then((response) => {

            let currentTask = response.result;
            let currentSubtask = currentTask.subtask;
            if (currentTask !== undefined && currentSubtask !== undefined) {

                currentSubtask.sort((a, b) => {
                    let nameA = a["timings_duration"];
                    let nameB = b["timings_duration"];

                    if (nameA < nameB) {
                        return 1;
                    }
                    if (nameA > nameB) {
                        return -1;
                    }
                    return 0;
                });
                currentTask.subtask = currentSubtask;
                setTask(currentTask);
                setTotalTimingsDuration(currentTask.timings_duration);
            }

        })
    }, [showedTask])

    return (<>
        <Modal show={showModalTaskStats} onHide={() => setShowModalTaskStats(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title className={`text-${appTheme === "dark" ? "black" : 'white'}`}>Statistiques de la tâche</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`bg-${appTheme === "dark" ? 'black' : 'white'} text-${appTheme !== "dark" ? 'black' : 'white'}`}>
                {task.id !== undefined && <>
                    <p className={`d-flex mb-2`}><span><Icon name="tag" fixedWidth className="me-3" />Statut</span><span className="ms-auto text-muted">{statusOptions.filter((option) => option.value === task.status).shift().label}</span></p>
                    <p className={`d-flex mb-2`}><span><Icon name="plus" fixedWidth className="me-3" />Date de création</span><span className="ms-auto text-muted">{formatDate(task.insert_date)}</span></p>
                    {(task.status == "progress" || task.status == "done") && <p className={`d-flex mb-2`}><span><Icon name="hourglass-clock" fixedWidth className="me-3" />Date de démarrage</span><span className="ms-auto text-muted">{formatDate(task.start_date)}</span></p>}
                    {(task.status == "done") && <p className={`d-flex mb-2`}><span><Icon name="flag-checkered" fixedWidth className="me-3" />Date de réalisation</span><span className="ms-auto text-muted">{formatDate(task.finish_date)}</span></p>}
                    <p className={`d-flex${task.subtask === undefined || task.subtask.length === 0 ? ` mb-0` : ' mb-2'}`}><span><Icon name="watch-smart" fixedWidth className="me-3" />Durée totale</span><span className="ms-auto text-muted">{formatDuration(totalTimingsDuration > 0 ? totalTimingsDuration : 0)}</span></p>
                    {task.subtask !== undefined && task.subtask.map((subtask) => (
                        <p key={subtask.id} className={`mb-0 d-flex`}><span className={`${subtask.is_done === '1' && ` text-decoration-line-through`}`}>{subtask.title}</span><span className="ms-auto text-muted">{subtask.timings_duration > 0 && `${formatDuration(subtask.timings_duration > 0 ? subtask.timings_duration : 0)} (${Math.round((subtask.timings_duration / totalTimingsDuration) * 100)}%)`}</span></p>
                    ))}
                </>}
            </Modal.Body>
        </Modal>
    </>)

}

export default ModalTaskStats;