import React, { useState, useContext, useLayoutEffect } from "react";

import { updateSubtask, deleteSubtask, fetchTasks } from '../services/tasks';
import { setColors, printTimingDuration } from "../libs/utils";

import { AppContext } from "../contexts/AppProvider";
import { TasksContext } from "../contexts/TasksProvider";

import { Form } from "react-bootstrap";

import ButtonIcon from "../ui/ButtonIcon";
import Icon from "../ui/Icon";
import BasicTooltip from "../ui/Tooltip";

function SubtaskItem({ subtask, taskID, task, totalSubtask, totalSubtaskDone, setTotalSubtaskDone, setPercentDone }) {

    // useLayoutEffect(() => {
    // 	setColors();
    // });
    const {
        lastAction, setLastAction
    } = useContext(AppContext);

    const {
        setTasks
    } = useContext(TasksContext);

    function handleCheckSubtask(taskID, subtaskID, isDone) {
        const newStatus = isDone === '0' ? '1' : '0';
        const subtaskData = {
            subtask_is_done: newStatus
        }
        updateSubtask(taskID, subtaskID, subtaskData).then((response) => {
            if (response.status === "Updated") {
                console.log("✅ Subtask updated");
                setLastAction([...lastAction, "update_subtask"]);
                if (newStatus === '1') {
                    let newTotal = totalSubtaskDone + 1;
                    setTotalSubtaskDone(newTotal);
                    setPercentDone((newTotal / totalSubtask) * 100);
                }
                else {
                    let newTotal = totalSubtaskDone - 1;
                    setTotalSubtaskDone(newTotal);
                    setPercentDone((newTotal / totalSubtask) * 100);
                }
                // setLastAction("create_subtask");
                fetchTasks().then((response) => {
                    setTasks(response.result);
                });
            }
        });
    }

    function handleUpdateSubtaskTitle(taskID, subtaskID, title, newTitle) {
        if (title !== newTitle) {
            if (newTitle !== "") {
                const subtaskData = {
                    subtask_title: newTitle
                }
                updateSubtask(taskID, subtaskID, subtaskData).then((response) => {
                    if (response.status === "Updated") {
                        console.log("✅ Subtask updated");
                        setLastAction([...lastAction, "update_subtask"]);
                    }
                });
            }
            else {
                deleteSubtask(taskID, subtaskID).then((response) => {
                    if (response.status === "Deleted") {
                        console.log("✅ Subtask deleted");
                        setLastAction([...lastAction, "delete_subtask"]);
                        let newTotal = totalSubtaskDone - 1;
                        setTotalSubtaskDone(newTotal);
                        setPercentDone((newTotal / totalSubtask) * 100);
                        fetchTasks().then((response) => {
                            setTasks(response.result);
                        });
                    }
                });
            }

        }
    }

    function handleSubtaskTiming(taskID, subtaskID, action) {
        const subtaskData = {
            subtask_action: action
        }
        updateSubtask(taskID, subtaskID, subtaskData).then((response) => {
            if (response.status === "Updated") {
                console.log(action === "startTiming" ? "✅ Subtask timing started" : "✅ Subtask timing ended");
                setLastAction([...lastAction, action === "startTiming" ? "start_timing_subtask" : "end_timing_subtask"]);
                fetchTasks().then((response) => {
                    setTasks(response.result);
                });
            }
        });
    }

    function handleSubtaskProgress(taskID, subtaskID, action) {
        const subtaskData = {
            subtask_action: action
        }
        updateSubtask(taskID, subtaskID, subtaskData).then((response) => {
            if (response.status === "Updated") {
                console.log(action === "startSubtask" ? "✅ Subtask started" : "✅ Subtask stopped");
                setLastAction([...lastAction, action === "startSubtask" ? "start_subtask" : "stop_subtask"]);
                fetchTasks().then((response) => {
                    setTasks(response.result);
                });
            }
        });
    }

    return (<>
        <div className="subtask d-flex">
            <Form.Check
                type="checkbox"
                id={`subtask-check-${subtask.id}`}
                className="me-3"
                checked={subtask.is_done === "1"}
                onChange={() => handleCheckSubtask(taskID, subtask.id, subtask.is_done)}
                disabled={task.is_archived === '1'}
            />
            <p className={`subtask-title${subtask.is_done === "1" ? " text-decoration-line-through" : ''}`} contentEditable={task.is_archived === '0'} onBlur={(e) => { handleUpdateSubtaskTitle(taskID, subtask.id, subtask.title, e.target.textContent) }} suppressContentEditableWarning={true}>{subtask.title}</p>
            {subtask.is_done === '0' && task.is_archived === '0' && <ButtonIcon
                name={subtask.in_progress === "0" ? "hourglass-start" : "hourglass-start"}
                className={`ms-auto${subtask.in_progress === "1" ? ' text-primary' : ' text-secondary'}`}
                tooltipContent={subtask.in_progress === "0" ? "Démarrer la sous-tâche" : `Arrêter la sous-tâche`}
                tooltipPlacement="top"
                onClick={(e) => { e.stopPropagation(); handleSubtaskProgress(taskID, subtask.id, subtask.in_progress === "0" ? 'startSubtask' : 'stopSubtask') }}
            />}
            {subtask.is_done === '0' && task.is_archived === '0' && <ButtonIcon
                name={subtask.active_timings === "0" ? "circle-play" : "circle-pause"}
                className={`ms-3${subtask.active_timings === "1" ? ' text-danger' : ''}`}
                tooltipContent={subtask.active_timings === "0" ? "Démarrer un compteur" : `Stopper un compteur<br/><em>Démarré depuis ${printTimingDuration(subtask.timings)}</em>`}
                tooltipPlacement="top"
                onClick={(e) => { e.stopPropagation(); handleSubtaskTiming(taskID, subtask.id, subtask.active_timings === "0" ? 'startTiming' : 'endTiming') }}
            />}
        </div>

    </>)

}

export default SubtaskItem;