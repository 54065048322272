import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import Icon from '../ui/Icon.jsx';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppProvider';
import { TasksContext } from '../contexts/TasksProvider';

function AppHeader({isHome}) {

    const { t, i18n } = useTranslation();

    const { appName, setShowedMenuApp } = useContext(AppContext);
    const { setShowedMenuTasks } = useContext(TasksContext);

    if(isHome) {
        return (
            <>
                <header className="App-header">
                    <h1>{appName}</h1>
                </header>
                <nav className='hamburger hamburger-left'>
                    <span onClick={() => setShowedMenuApp(true)}><Icon name="chevron-right" /></span>
                </nav>
                <nav className='hamburger hamburger-right'>
                    <span onClick={() => setShowedMenuTasks(true)}><Icon name="bars" /></span>
                </nav>
            </>
        )
    }

    return (
        <>
            <header className="App-header">
                <h1><Link to="/">{appName}</Link></h1>
            </header>
            <nav className='hamburger hamburger-left'>
                <span onClick={() => setShowedMenuApp(true)}><Icon name="bars" /></span>
            </nav>
            <nav className='hamburger hamburger-right'>
                    <span onClick={() => setShowedMenuTasks(true)}><Icon name="bars" /></span>
                </nav>
        </>
    )

}

export default AppHeader;