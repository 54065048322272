import React, { useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { Col, Badge } from 'react-bootstrap';

import { TasksContext } from "../contexts/TasksProvider";

import TaskItem from "./TaskItem";

import Icon from "../ui/Icon";

import { updateTasksIndex } from "../services/tasks";
import { useEffect } from "react";

const SortableItem = sortableElement(({ status, task }) =>
    <TaskItem
        status={status}
        task={task}
    />
);

const SortableContainer = sortableContainer(({ isCollapsed, children }) => {
    return <div className={`tasksList`}>{children}</div>;
});

const StatusTasks = ({ status }) => {


    const statusIcon = {
        "todo": 'calendar-day',
        "progress": 'hourglass-clock',
        "done": 'flag-checkered',
    }
    const { t } = useTranslation();
    const { tasksViewArchived, tasksTypesFilter, tasks, setTasks } = useContext(TasksContext);

    const [statusTasks, setStatusTasks] = useState([]);

    const [isSorting, setIsSorting] = useState("0");

    useEffect(() => {
        setStatusTasks(tasks[status]);
        console.log(tasks[status]);
    }, [tasks]);

    function handleUpdateStatusTasksIndex(item, status) {
        let neweTasks = statusTasks;
        // let \newSubtasks = task.subtask;
        const taskMovedIndex = statusTasks.findIndex((task) => parseInt(task.index) === parseInt(item.oldIndex));
        const taskTargetIndex = statusTasks.findIndex((task) => parseInt(task.index) === parseInt(item.newIndex));
        const movedTask = neweTasks.splice(taskMovedIndex, 1).shift();
        neweTasks.splice(taskTargetIndex, 0, movedTask);
        let tasksOrder = [];
        let i = 1;
        neweTasks.forEach((element, index) => {
            let taskOrder = {
                id: element.id,
                index: i
            }
            tasksOrder.push(taskOrder);
            neweTasks[index].index = i + "";
            i++;
        });
        let newTasks = { ...tasks };
        newTasks[status] = neweTasks;
        setTasks(newTasks);
        // setIsSorting(false);
        const tasksData = {
            tasks: tasksOrder,
        }
        updateTasksIndex(tasksData).then((response) => {
            if (response.status === "Updated") {
                console.log("✅ Tasks index updated");
            }
        });
    };

    return (
        <Col sm="4" >
            <h4 style={{ marginBottom: 15 }} className="d-flex">
                <span>
                    <Icon name={statusIcon[status]} className="me-3 text-muted" />
                    {t(`task_status_${status}`)}
                    <Badge className="badge-primary">
                        {statusTasks.filter(task => !tasksViewArchived ? task.is_archived === "0" : true).length}
                    </Badge>
                </span>
                <span className="ms-auto" onClick={() => { setIsSorting(isSorting === '1' ? '0' : '1') }}>
                    <Icon name={isSorting === '1' ? 'check' : 'arrows'} className="is-clickable" />
                </span>
            </h4>
            <div className={isSorting === '1' ? "sorting" : 'not-sorting'}>
                <SortableContainer
                    helperClass="sorting-helper"
                    onSortEnd={(item) => handleUpdateStatusTasksIndex(item, status)}
                    isSorting={isSorting}
                >
                    {statusTasks.filter(task => tasksTypesFilter.length >= 1 ? tasksTypesFilter.includes(task.type) : true).filter(task => !tasksViewArchived ? task.is_archived === "0" : true).map((task) => (
                        <SortableItem
                            disabled={isSorting === "0"}
                            helperClass="dragging-helper-class"
                            key={`task-${task.id}`}
                            index={parseInt(task.index)}
                            status={status}
                            task={task}
                        />
                    ))}
                </SortableContainer>
            </div>
        </Col >
    )
}

export default StatusTasks