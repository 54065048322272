import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import selectStyles from '../ui/selectStyles';
import { CustomOption, CustomOptionFont, CustomSingleValue } from '../ui/selectComponents';
import { CirclePicker } from 'react-color';
import { Offcanvas, Form } from 'react-bootstrap';

import { AppContext } from '../contexts/AppProvider.jsx';

import Icon from '../ui/Icon';
import { fetchGoogleFonts } from '../services/application';

function MenuApp() {

    const {
        appName, setAppName,
        appLang, setAppLang,
        appTheme, setAppTheme,
        appThemeColor, setAppThemeColor,
        appThemeIconClass, setAppThemeIconClass,
        appThemeTitleGoogleFont, setAppThemeTitleGoogleFont,
        appThemeTextGoogleFont, setAppThemeTextGoogleFont,
        showedMenuApp, setShowedMenuApp
    } = useContext(AppContext);

    const langOptions = [
        { value: 'fr_FR', label: 'Français', flag: '🇫🇷' },
        { value: 'en_US', label: 'English', flag: '🇬🇧' },
    ];

    const iconClassOptions = [
        { value: 'solid', label: 'Solid', icon: 'fort', iconClass: 'solid' },
        { value: 'regular', label: 'Regular', icon: 'fort', iconClass: 'regular' },
        { value: 'light', label: 'Light', icon: 'fort', iconClass: 'light' },
        { value: 'thin', label: 'Thin', icon: 'fort', iconClass: 'thin' },
        { value: 'duotone', label: 'Duotone', icon: 'fort', iconClass: 'duotone' },
        // { value: 'sharp-solid', label: 'Sharp', icon: 'fort', iconClass: 'sharp-solid' },
    ];

    const colors = ["#f44336", "#d01c1f", "#e91e63", "#cd2c70", "#9c27b0", "#673ab7", "#3f51b5", "#4a80be", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#39a744", "#8bc34a", "#c1db3c", "#cddc39", "#b7b079", "#ffeb3b", "#f6d003", "#ffc107", "#ff9800", "#fe7e03", "#ff5722", "#795548", "#ab8266", '#fa9a84', '#fdc2c6', '#d4c9cd', '#f4d7c5', '#c8e0e0', '#a0c3d9', "#607d8b"];

    const [selectedLang, setSelectedLang] = useState({});
    const [selectedIconClass, setSelectedIconClass] = useState({});
    const [selectedTitleGoogleFont, setSelectedTitleGoogleFont] = useState({});
    const [selectedTextGoogleFont, setSelectedTextGoogleFont] = useState({});

    const [googleFontsOptions, setGoogleFontsOptions] = useState([]);

    useEffect(() => {
        fetchGoogleFonts().then((response) => {
            const fonts = response.result;
            const googleFonts = [];
            for (const [key, value] of Object.entries(fonts)) {
                const googleFont = {
                    'value': value.family,
                    'label': value.family,
                    'category': value.category,
                }
                googleFonts.push(googleFont);
            }
            googleFonts.sort(orderFonts);
            // console.log(googleFonts);
            setGoogleFontsOptions(googleFonts);
            setSelectedTitleGoogleFont(googleFonts.filter((a_font) => a_font.value === appThemeTitleGoogleFont).shift());
            setSelectedTextGoogleFont(googleFonts.filter((a_font) => a_font.value === appThemeTextGoogleFont).shift());
        });
    }, []);

    useEffect(() => {
        setSelectedLang(langOptions.filter((lang) => (lang.value === appLang)).shift());
    }, [appLang]);

    useEffect(() => {
        setSelectedIconClass(iconClassOptions.filter((iconClass) => (iconClass.value === appThemeIconClass)).shift());
    }, [appThemeIconClass]);

    const selectLang = (option) => {
        setSelectedLang(option);
        setAppLang(option.value);
    }

    const selectIconClass = (option) => {
        setSelectedIconClass(option);
        setAppThemeIconClass(option.value);
    }

    const selectTitleGoogleFont = (option) => {
        setSelectedTitleGoogleFont(option);
        setAppThemeTitleGoogleFont(option.value);
    }

    const selectTextGoogleFont = (option) => {
        setSelectedTextGoogleFont(option);
        setAppThemeTextGoogleFont(option.value);
    }

    const orderFonts = (a, b) => {
        const groupOrder = ['serif', 'sans-serif', 'handwriting', 'display', 'monospace'];

        const indexA = groupOrder.findIndex(g => g == a.category);
        const indexB = groupOrder.findIndex(g => g == b.category);

        if (indexA < indexB) {
            return -1;
        }
        if (indexA > indexB) {
            return 1;
        }

        return 0;
    }


    return (
        <Offcanvas show={showedMenuApp} onHide={() => setShowedMenuApp(false)} className={appTheme === "dark" ? 'bg-black text-white' : 'bg-white text-black'}>
            <Offcanvas.Header closeButton closeVariant={appTheme === "dark" ? "white" : ""}>
                <Offcanvas.Title className='text-theme'>Application</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <p className='h6 text-center text-muted'><Icon name="wrench" className="me-2" />Réglages</p>
                <Form.Label htmlFor="app-name">Nom de l'applcation</Form.Label>
                <Form.Control
                    type="text"
                    id="app-name"
                    value={appName}
                    onChange={(event) => {
                        setAppName(event.target.value);
                    }}
                />
                <Form.Label htmlFor="theme-color" className='mt-3'>Langue</Form.Label>
                <Select
                    className="mb-5" isSearchable={false}
                    options={langOptions}
                    value={selectedLang}
                    onChange={selectLang}
                    styles={selectStyles}
                    components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                />
                <p className='h6 text-center text-muted'><Icon name="paint-brush" className="me-2" />Thème</p>

                <Form.Label htmlFor="theme-color" className=''>Type d'icône</Form.Label>
                <Select
                    isSearchable={false} menuPlacement="auto"
                    options={iconClassOptions}
                    value={selectedIconClass}
                    onChange={selectIconClass}
                    styles={selectStyles}
                    components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                />
                <Form.Label htmlFor="theme-font" className='mt-3'>Police des titres</Form.Label>
                <Select
                    isSearchable={true} menuPlacement="auto"
                    options={googleFontsOptions}
                    value={selectedTitleGoogleFont}
                    onChange={selectTitleGoogleFont}
                    styles={selectStyles}
                    components={{ Option: CustomOptionFont, SingleValue: CustomSingleValue }}
                />
                <Form.Label htmlFor="theme-font" className='mt-3'>Police du texte</Form.Label>
                <Select
                    isSearchable={true} menuPlacement="auto"
                    options={googleFontsOptions}
                    value={selectedTextGoogleFont}
                    onChange={selectTextGoogleFont}
                    styles={selectStyles}
                    components={{ Option: CustomOptionFont, SingleValue: CustomSingleValue }}
                />
                <Form.Label htmlFor="theme-color" className='mt-3'>Couleur du thème</Form.Label>
                <CirclePicker
                    color={appThemeColor}
                    colors={colors}
                    onChange={(color) => {
                        setAppThemeColor(color.hex);
                    }}
                    className="mb-2" width={348} circleSize={36} circleSpacing={22}
                />
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Activer le mode Sombre"
                    onChange={() => setAppTheme(() => appTheme === 'dark' ? 'light' : 'dark')}
                    checked={appTheme === "dark"}
                />
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default MenuApp;