import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { setColors, printTimingDuration, formatDuration } from "../libs/utils";
import ReactGA from "react-ga4";
import { Badge, ProgressBar, Tooltip } from "react-bootstrap";
import { AppContext } from '../contexts/AppProvider';
import { TasksContext } from "../contexts/TasksProvider";
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import { updateTask, deleteTask, updateSubtasksIndex, fetchTasks } from '../services/tasks';

import SubtaskItem from "./SubtaskItem";

import Icon from "../ui/Icon";
import ButtonIcon from "../ui/ButtonIcon";
import BasicTooltip from "../ui/Tooltip";

const SortableItem = sortableElement(({ subtask, taskID, task, totalSubtask, totalSubtaskDone, setTotalSubtaskDone, setPercentDone }) =>
    <SubtaskItem
        subtask={subtask}
        taskID={taskID} task={task}
        totalSubtaskDone={totalSubtaskDone} totalSubtask={totalSubtask}
        setTotalSubtaskDone={setTotalSubtaskDone} setPercentDone={setPercentDone}
    />
);

const SortableContainer = sortableContainer(({ isCollapsed, children }) => {
    return <div className={`subtask-list${isCollapsed ? " collapsed" : ""}`}>{children}</div>;
});


function TaskItem({ task, status }) {

    const { t } = useTranslation();

    const {
        appTheme,
        lastAction, setLastAction
    } = useContext(AppContext);

    const {
        setShowModalTask, setModeModalTask,
        setShowModalSubtask, setShowModalTaskStats,
        setEditedTask, setShowedTask,
        types, tags,
        tasks, setTasks
    } = useContext(TasksContext);


    const [taskType, setTaskType] = useState(types.filter((type) => (type.value === task.type)).shift());

    const [openedTasks, setOpenedTasks] = useState(localStorage.getItem("openTasks") ? localStorage.getItem("openTasks").split("|") : []);

    const [isCollapsed, setIsCollapsed] = useState(!openedTasks.includes(task.id));
    const [isSorting, setIsSorting] = useState(false);


    const [totalSubtask, setTotalSubtask] = useState(task.subtask.filter(subtask => subtask.is_archived === "0").length);
    const [totalSubtaskDone, setTotalSubtaskDone] = useState(task.subtask.filter(subtask => subtask.is_archived === "0").filter((subtask) => subtask.is_done === '1').length);
    const [percentDone, setPercentDone] = useState((totalSubtaskDone / totalSubtask) * 100);

    const [taskTimingsDuration, setTaskTimingsDuration] = useState(0);
    const [taskActiveTimingDuration, setTaskActiveTimingDuration] = useState(0);
    const [taskHasActiveTiming, setTaskHasActiveTiming] = useState(false);



    // useLayoutEffect(() => {
    // 	setColors();
    // });



    useEffect(() => {
        let newTotal = task.subtask.filter(subtask => subtask.is_archived === "0").length,
            newTotalDone = task.subtask.filter(subtask => subtask.is_archived === "0").filter((subtask) => subtask.is_done === '1').length,
            newPercent = (newTotalDone / newTotal) * 100;
        setTotalSubtask(newTotal);
        setTotalSubtaskDone(newTotalDone);
        setPercentDone(newPercent);
        let timingsDuration = 0;
        let activeTimingsDuration = 0;
        task.timings.forEach((timing) => {
            const timingStart = new Date(timing.start).getTime();
            const timingEnd = timing.end ? new Date(timing.end).getTime() : Date.now();
            let timingDuration = timingEnd - timingStart;
            timingDuration = Math.floor(timingDuration / 1000);
            console.log(timingStart);
            timingsDuration += timingDuration;
            if (timing.is_ended === '0') {
                activeTimingsDuration += timingDuration;
                setTaskHasActiveTiming(true);
            }

        });
        console.log(timingsDuration);
        setTaskTimingsDuration(timingsDuration);
        setTaskActiveTimingDuration(activeTimingsDuration);
        setTaskType(types.filter((type) => (type.value === task.type)).shift())
        // console.log("timingsDuration",timingsDuration);
    }, [task]);

    // useEffect(() => { 
    //     setTaskType(types.filter((type) => (type.value === task.type)).shift())
    // },[types]);

    useEffect(() => {
        if (taskHasActiveTiming) {
            const timer = setTimeout(() => {
                setTaskActiveTimingDuration(taskActiveTimingDuration + 1);
            }, 1000);
            const timer2 = setTimeout(() => {
                setTaskTimingsDuration(taskTimingsDuration + 1);
            }, 1000);
            return () => { clearTimeout(timer); clearTimeout(timer2) };
        }
    }, [taskHasActiveTiming, taskActiveTimingDuration]);

    useEffect(() => {
        let openTasks = localStorage.getItem("openTasks") ? localStorage.getItem("openTasks").split("|") : [];
        if (isCollapsed) {
            if (openTasks.includes(task.id)) {
                openTasks = openTasks.filter((aTaskID) => aTaskID !== task.id);
                ReactGA.event({
                    category: "Actions",
                    action: "Close_task",
                    label: "Close a task", // optional
                    value: parseInt(task.id)
                });
            }
        }
        else {
            if (!openTasks.includes(task.id)) {
                openTasks.push(task.id);
                ReactGA.event({
                    category: "Actions",
                    action: "Open_task",
                    label: "Open a task", // optional
                    value: parseInt(task.id)
                });
            }
        }
        setOpenedTasks(openTasks);
        localStorage.setItem("openTasks", openTasks.join("|"));
    }, [isCollapsed]);

    function handleUpdateTaskTitle(taskID, title, newTitle) {
        if (title !== newTitle) {
            if (newTitle !== "") {
                const taskData = {
                    task_title: newTitle
                }
                updateTask(taskID, taskData).then((response) => {
                    if (response.status === "Updated") {
                        console.log("✅ Task updated");
                        setLastAction([...lastAction, "update_task"]);
                    }
                });
            }
            else {
                deleteTask(taskID).then((response) => {
                    if (response.status === "Deleted") {
                        console.log("✅ Task archived");
                        setLastAction([...lastAction, "archive_task"]);
                        fetchTasks().then((response) => {
                            setTasks(response.result);
                        });
                    }
                });
            }

        }
    }

    function handleTaskTiming(taskID, action) {
        const taskData = {
            task_action: action
        }
        updateTask(task.id, taskData).then((response) => {
            if (response.status === "Updated") {
                console.log(action === "startTiming" ? "✅ Task timing started" : "✅ Task timing ended");
                setLastAction([...lastAction, action === "startTiming" ? "start_timing_task" : "end_timing_task"]);
                if (action === "startTiming") {
                    setTaskActiveTimingDuration(0); setTaskHasActiveTiming(true);
                }
                else {
                    setTaskHasActiveTiming(false);
                }
                fetchTasks().then((response) => {
                    setTasks(response.result);
                });
            }
        });
    }

    function handleTaskStart(taskID) {
        const taskData = {
            task_action: 'startTask'
        }
        updateTask(task.id, taskData).then((response) => {
            if (response.status === "Updated") {
                console.log("✅ Task started");
                setLastAction([...lastAction, "start_task"]);
                fetchTasks().then((response) => {
                    setTasks(response.result);
                });
            }
        });
    }

    function handleTaskFinish(taskID) {
        const taskData = {
            task_action: 'finishTask'
        }
        updateTask(task.id, taskData).then((response) => {
            if (response.status === "Updated") {
                console.log("✅ Task finished");
                setLastAction([...lastAction, "finish_task"]);
                fetchTasks().then((response) => {
                    setTasks(response.result);
                });
            }
        });
    }

    function handleTaskArchive(taskID) {
        deleteTask(task.id).then((response) => {
            if (response.status === "Deleted") {
                console.log("✅ Task archived");
                setLastAction([...lastAction, "archive_task"]);
                fetchTasks().then((response) => {
                    setTasks(response.result);
                });
            }
        });
    }

    function handleTaskDelete(taskID) {
        deleteTask(task.id).then((response) => {
            if (response.status === "Deleted") {
                console.log("✅ Task deleted");
                setLastAction([...lastAction, "delete_task"]);
                fetchTasks().then((response) => {
                    setTasks(response.result);
                });
            }
        });
    }

    function handleUpdateSubtaskIndex(item, taskID) {
        const taskIndex = tasks[status].findIndex((task) => task.id === taskID);
        let newSubtasks = task.subtask;
        const subtaskMovedIndex = task.subtask.findIndex((subtask) => parseInt(subtask.index) === parseInt(item.oldIndex));
        const subtaskTargetIndex = task.subtask.findIndex((subtask) => parseInt(subtask.index) === parseInt(item.newIndex));
        const movedSubtask = newSubtasks.splice(subtaskMovedIndex, 1).shift();
        newSubtasks.splice(subtaskTargetIndex, 0, movedSubtask);
        let subtasksOrder = [];
        let i = 1;
        newSubtasks.forEach((element, index) => {
            let subtaskOrder = {
                id: element.id,
                index: i
            }
            subtasksOrder.push(subtaskOrder);
            newSubtasks[index].index = i + "";
            i++;
        });
        let newTasks = { ...tasks };
        newTasks[status][taskIndex].subtask = newSubtasks;
        setTasks(newTasks);
        setIsSorting(false);
        const subtasksData = {
            subtasks: subtasksOrder,
        }
        updateSubtasksIndex(taskID, subtasksData).then((response) => {
            if (response.status === "Updated") {
                console.log("✅ Subtasks index updated");
            }
        });
    };

    return (<>
        <div className={`task-wrapper${isSorting ? " sorting" : ''}${task.is_archived === '1' ? ' archived' : ''}`}>
            <div className={`task`}>
                <div className="task-item d-flex">

                    <span>
                        {/* Type de tâches */}
                        {taskType !== undefined &&
                            <span className={"text-" + taskType.text_color} style={{ fontSize: '1.2em', marginRight: 15 }}>
                                <BasicTooltip content={taskType.label} placement="top">
                                    <Icon name={taskType.icon.replace("fa-", "")} />
                                </BasicTooltip>
                            </span>}
                        {/* Nom de la tâche */}

                        <BasicTooltip content={`${task.description ? task.description + '<br/>' : ''}${`<em>Temps total : ${formatDuration(taskTimingsDuration > 0 ? taskTimingsDuration : 0)}</em>`}`} placement="top">
                            <span className="task-title" contentEditable={task.is_archived === '0'} onBlur={(e) => { handleUpdateTaskTitle(task.id, task.title, e.target.textContent) }} suppressContentEditableWarning={true}>
                                {task.title}
                            </span>
                        </BasicTooltip>

                    </span>
                    <span className="ms-auto">

                        {task.is_archived === '0' && <ButtonIcon
                            name="diagram-subtask"
                            className=""
                            tooltipContent={t("create_subtask")}
                            tooltipPlacement="top"
                            onClick={() => { setEditedTask(task.id); setShowModalSubtask(true); }}
                        />}
                        {task.is_archived === '0' && <ButtonIcon
                            name="pencil"
                            className="ms-3"
                            tooltipContent={t("edit_task")}
                            tooltipPlacement="top"
                            onClick={() => { setEditedTask(task.id); setModeModalTask("edit"); setShowModalTask(true); }}
                        />}
                        {task.is_archived === '0' && <ButtonIcon
                            name="chart-simple"
                            className="ms-3"
                            tooltipContent="Statistiques de la tâche"
                            tooltipPlacement="top"
                            onClick={() => { setShowedTask(task.id); setShowModalTaskStats(true); }}
                        />}

                        {task.status === "todo" && task.is_archived === '0' && <ButtonIcon
                            name="hourglass-clock"
                            className="ms-3"
                            tooltipContent="Démarrer la tâche"
                            tooltipPlacement="top"
                            onClick={() => { handleTaskStart(task.id); }}
                        />}
                        {task.status === "progress" && task.is_archived === '0' && <ButtonIcon
                            name="flag-checkered"
                            className="ms-3"
                            tooltipContent="Terminer la tâche"
                            tooltipPlacement="top"
                            onClick={() => { handleTaskFinish(task.id); }}
                        />}
                        {task.status === "done" && task.is_archived === '0' && <ButtonIcon
                            name="box-archive"
                            className="ms-3"
                            tooltipContent="Archiver la tâche"
                            tooltipPlacement="top"
                            onClick={() => { handleTaskArchive(task.id); }}
                        />}
                        {task.is_archived === '1' && <ButtonIcon
                            name="trash"
                            className="ms-3"
                            textColor={"danger"}
                            tooltipContent={t('delete_task')}
                            tooltipPlacement="top"
                            onClick={() => { handleTaskDelete(task.id); }}
                        />}
                        {task.is_archived === '0' && <ButtonIcon
                            name={task.active_timings === "0" ? "circle-play" : "circle-pause"}
                            className={`ms-3${task.active_timings === "1" ? ' text-danger' : ''}`}
                            tooltipContent={task.active_timings === "0" ? t("start_timing") : `${t("stop_timing")}<br/><em>Démarré depuis ${formatDuration(taskActiveTimingDuration)}</em>`}
                            tooltipPlacement="top"
                            onClick={() => { handleTaskTiming(task.id, task.active_timings === "0" ? 'startTiming' : 'endTiming') }}
                        />}
                    </span>
                </div>
                {task.tags.split(",").filter((tag) => tag !== "").length >= 1 && <div className="task-tags text-start">
                    {task.tags.split(",").filter((tag) => tag !== "").map((tag) => (
                        <span key={tag} className={`task-tag text-${tags.filter((a_tag) => a_tag.value == tag).length > 0 ? tags.filter((a_tag) => a_tag.value == tag).shift().text_color : 'default'} border-${tags.filter((a_tag) => a_tag.value == tag).length > 0 ? tags.filter((a_tag) => a_tag.value == tag).shift().text_color : 'default'}`}>{tag}</span>
                    ))}
                </div>}
                {task.subtask.length > 0 && <div className="subtask-info d-flex flex-column pb-2">
                    <div className="d-flex">
                        {task.subtask.length > 0 && <span className="text-dark me-3" onClick={() => { setIsCollapsed(!isCollapsed) }}>
                            <Icon name={isCollapsed ? "chevrons-right" : "chevrons-down"} />
                        </span>}
                        <p style={{ textAlign: 'left', fontWeight: '200', marginBottom: 5 }}>
                            {t("subtasks")}<Badge bg={appTheme === "dark" ? 'dark' : 'gray-300'} text={appTheme === "dark" ? 'white' : 'black'} className="ms-2" style={{ verticalAlign: 'top', fontWeight: '300', lineHeight: '8px', marginTop: 3, paddingTop: 5 }}>{totalSubtaskDone}/{totalSubtask}</Badge>
                        </p>

                        <span className="ms-auto">
                            <BasicTooltip content={`${Number.isInteger(Math.floor(percentDone)) ? Math.floor(percentDone) : '0'}%`} placement="bottom">
                                <ProgressBar now={percentDone} style={{ width: 140, height: 10, marginTop: 7 }} />
                            </BasicTooltip>
                        </span>
                    </div>
                </div>}
            </div>
            {task.subtask.length > 0 &&
                <SortableContainer
                    helperClass="sorting-helper"
                    onSortEnd={(item) => handleUpdateSubtaskIndex(item, task.id)}
                    onSortStart={(item) => { setIsSorting(true) }}
                    distance={1} isCollapsed={isCollapsed}
                >
                    {task.subtask.map((value) => (
                        <SortableItem
                            disabled={task.is_archived === '1'}
                            helperClass="dragging-helper-class"
                            key={`subtask-${value.id}`}
                            index={parseInt(value.index)}
                            subtask={value}
                            taskID={task.id} task={task}
                            totalSubtaskDone={totalSubtaskDone} totalSubtask={totalSubtask}
                            setTotalSubtaskDone={setTotalSubtaskDone} setPercentDone={setPercentDone}
                        />
                    ))}
                </SortableContainer>}
        </div>
    </>)

}

export default TaskItem;