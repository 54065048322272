import { useState, createContext } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ originalIsAdmin, originalAppName, originalAppLang, originalAppTheme, originalAppThemeColor, originalAppThemeIconClass, originalAppThemeTitleGoogleFont, originalAppThemeTextGoogleFont, children }) => {

    const [isAdmin, setIsAdmin] = useState(originalIsAdmin);

    const [appName, setAppName] = useState(originalAppName);
    const [appLang, setAppLang] = useState(originalAppLang);
    const [appTheme, setAppTheme] = useState(originalAppTheme);
    const [appThemeColor, setAppThemeColor] = useState(originalAppThemeColor);
    const [appThemeIconClass, setAppThemeIconClass] = useState(originalAppThemeIconClass);
    const [appThemeTitleGoogleFont, setAppThemeTitleGoogleFont] = useState(originalAppThemeTitleGoogleFont);
    const [appThemeTextGoogleFont, setAppThemeTextGoogleFont] = useState(originalAppThemeTextGoogleFont);

    const [appOriginalName, setAppOriginalName] = useState(originalAppName);
    const [appOriginalLang, setAppOriginalLang] = useState(originalAppLang);
    const [appOriginalTheme, setAppOriginalTheme] = useState(originalAppTheme);
    const [appOriginalThemeColor, setAppOriginalThemeColor] = useState(originalAppThemeColor);
    const [appOriginalThemeIconClass, setAppOriginalThemeIconClass] = useState(originalAppThemeIconClass);
    const [appOriginalThemeTitleGoogleFont, setAppOriginalThemeTitleGoogleFont] = useState(originalAppThemeTitleGoogleFont);
    const [appOriginalThemeTextGoogleFont, setAppOriginalThemeTextGoogleFont] = useState(originalAppThemeTextGoogleFont);

    const [showedMenuApp, setShowedMenuApp] = useState(false);

    const [lastAction, setLastAction] = useState([]);

    return (
        <AppContext.Provider value={{
            isAdmin, setIsAdmin,
            appName, setAppName,
            appLang, setAppLang,
            appTheme, setAppTheme,
            appThemeColor, setAppThemeColor,
            appThemeIconClass, setAppThemeIconClass,
            appThemeTitleGoogleFont, setAppThemeTitleGoogleFont,
            appThemeTextGoogleFont, setAppThemeTextGoogleFont,
            appOriginalName, setAppOriginalName,
            appOriginalLang, setAppOriginalLang,
            appOriginalTheme, setAppOriginalTheme,
            appOriginalThemeColor, setAppOriginalThemeColor,
            appOriginalThemeIconClass, setAppOriginalThemeIconClass,
            appOriginalThemeTitleGoogleFont, setAppOriginalThemeTitleGoogleFont,
            appOriginalThemeTextGoogleFont, setAppOriginalThemeTextGoogleFont,
            showedMenuApp, setShowedMenuApp,
            lastAction, setLastAction
        }}>
            {children}
        </AppContext.Provider>
    )
}