import React, { useState, useContext } from 'react';

import { createType, updateType, fetchTypes, fetchTasks } from '../services/tasks';
import { setColors } from '../libs/utils';
import { Button, Modal, Form, Table, InputGroup } from 'react-bootstrap';

import { AppContext } from '../contexts/AppProvider';
import { TasksContext } from '../contexts/TasksProvider';

import Select, { components } from 'react-select';
import selectStyles from '../ui/selectStyles';
import { CustomOption, CustomSingleValue } from '../ui/selectComponents';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

import Icon from '../ui/Icon';
import { useEffect } from 'react';

const textColorsOptions = [
    { value: 'default', label: 'Noir/Blanc', text_color: 'default' },
    { value: 'primary', label: 'Principale', text_color: 'success' },
    { value: 'secondary', label: 'Secondaire', text_color: 'secondary' },
    { value: 'blue', label: 'Bleu', text_color: 'blue' },
    { value: 'purple', label: 'Violet', text_color: 'purple' },
    { value: 'green', label: 'Vert', text_color: 'green' },
    { value: 'orange', label: 'Orange', text_color: 'orange' },
    { value: 'red', label: 'Rouge', text_color: 'red' },
    { value: 'yellow', label: 'Jaune', text_color: 'yellow' },
];

function ModalTypes() {

    const { appTheme } = useContext(AppContext);
    const {
        showModalTypes, setShowModalTypes,
        types, setTypes, setTasks
    } = useContext(TasksContext);

    const [typeTitle, setTypeTitle] = useState("");
    const [typeTextColor, setTypeTextColor] = useState("default");
    const [typeIcon, setTypeIcon] = useState("check");

    const [selectedTextColor, setSelectedTextColor] = useState(textColorsOptions[0]);

    const [formIsOpen, setFormIsOpen] = useState(false);
    const [formMode, setFormMode] = useState("add");
    const [editedType, setEditedType] = useState(null);

    useEffect(() => {
        setFormIsOpen(false);
    }, [showModalTypes])

    useEffect(() => {
        if (editedType && formMode === "edit") {
            const type = types.filter((item) => item.value === editedType).shift();
            // console.log(type);
            setTypeTitle(type.label);
            setTypeTextColor(type.text_color);
            setSelectedTextColor(textColorsOptions.filter((item) => item.value === type.text_color).shift());
            setTypeIcon(type.icon);
        }
    }, [editedType]);

    useEffect(() => {
        if (!formIsOpen) {
            // const type = types.filter((item) => item.value === editedType).shift();
            // console.log(type);
            setTypeTitle("");
            setTypeTextColor("default");
            setSelectedTextColor(textColorsOptions[0]);
            setTypeIcon("check");
        }
    }, [formIsOpen]);

    const handleSubmit = (event) => {
        event.preventDefault();
        // setLastAction("");
        const typeData = {
            type_title: typeTitle,
            type_text_color: typeTextColor,
            type_icon: typeIcon,
        }

        if (formMode === "add") {
            createType(typeData).then((response) => {
                if (response.status === "Created") {
                    console.log("✅ Type created");
                    // handleClose();
                    // setLastAction([...lastAction,"create"]);
                    fetchTypes().then((response) => {
                        const typesData = [];
                        const newTypeData = {};
                        response.result.map((type) => {
                            const result = {
                                value: type.handle,
                                label: type.title,
                                icon: type.icon.replace("fa-", ""),
                                text_color: type.text_color.replace("white", "black")
                            }
                            // if()
                            typesData.push(result);
                        });
                        setTypes(typesData);
                        setFormIsOpen(false);

                    });
                }
            });
        }

        if (formMode === "edit") {
            updateType(editedType, typeData).then((response) => {
                if (response.status === "Updated") {
                    console.log("✅ Type updated");
                    // handleClose();
                    // setLastAction([...lastAction,"create"]);
                    fetchTypes().then((response) => {
                        const typesData = [];
                        response.result.map((type) => {
                            const result = {
                                value: type.handle,
                                label: type.title,
                                icon: type.icon.replace("fa-", ""),
                                text_color: type.text_color.replace("white", "black")
                            }
                            typesData.push(result);
                        });
                        setTypes(typesData);
                        fetchTasks().then((response) => {
                            setTypes(typesData);
                            setTasks(response.result);
                            setFormIsOpen(false);
                        });

                        // setFormIsOpen(false);
                    });
                }
            });
        }



    }


    return (
        <>

            <Modal show={showModalTypes} onHide={() => setShowModalTypes(false)} centered>

                <Modal.Header closeButton>
                    <Modal.Title className={`text-${appTheme === "dark" ? "black" : 'white'}`}>Gestion des types</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`theme-${appTheme === "dark" ? 'dark' : 'light'} bg-${appTheme === "dark" ? 'black' : 'white'} text-${appTheme !== "dark" ? 'black' : 'white'}`}>
                    {!formIsOpen && <>
                        <div className='h6 text-center text-theme fw-400'>Liste des types</div>
                        <Table bordered variant={appTheme === "dark" ? "dark" : ''}>
                            {/* <thead>
                            <tr>
                                <th>Types</th>
                            </tr>
                        </thead> */}
                            <tbody>
                                <tr>
                                    <td className={`text-default`} style={{ lineHeight: '30px' }}>
                                        <div className='d-flex'>
                                            <span>
                                                <Icon name="check" className="me-2" />
                                                Tâche
                                            </span>
                                            {/* <Button 
                                            variant='outline-primary' 
                                            size="sm" 
                                            className='ms-auto'
                                            onClick={() => { setFormMode("edit"); setEditedType("tache"); setFormIsOpen(true); }} 
                                        >Modifier</Button> */}
                                        </div>
                                    </td>
                                </tr>
                                {types.filter((item) => item.value !== "tache").map((type) => {
                                    return (
                                        <tr key={type.value}>
                                            <td className={`text-${type.text_color !== "black" ? type.text_color : 'white'}`} style={{ lineHeight: '30px' }}>
                                                <div className='d-flex'>
                                                    <span>
                                                        <Icon name={type.icon} className="me-2" />
                                                        {type.label}
                                                    </span>
                                                    <Button
                                                        variant='outline-primary'
                                                        size="sm"
                                                        className='ms-auto'
                                                        onClick={() => { setFormMode("edit"); setEditedType(type.value); setFormIsOpen(true); }}
                                                    ><Icon name="pencil" className="me-2" />Modifier</Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <div className='d-flex'>
                            <Button variant="outline-primary" size="sm" className='w-100' onClick={() => { setFormMode("add"); setEditedType(null); setFormIsOpen(true) }}><Icon name="plus" className="me-2" />Nouveau type</Button>
                        </div>
                    </>}
                    {formIsOpen && <>
                        <div className='h6 text-center text-theme fw-400'>{formMode === "add" ? 'Nouveau type' : 'Modifier le type'}</div>
                        <Form onSubmit={handleSubmit}>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="app-name">Nom du type</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="type-name"
                                    placeholder="Entrez un nom"
                                    value={typeTitle}
                                    onChange={(event) => {
                                        setTypeTitle(event.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Couleur du texte</Form.Label>
                                <Select
                                    options={textColorsOptions}
                                    styles={selectStyles}
                                    value={selectedTextColor}
                                    onChange={(option) => { setSelectedTextColor(option); setTypeTextColor(option.value); }}
                                    components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                                />
                            </Form.Group>
                            <Form.Group style={{ marginBottom: 35 }}>
                                <Form.Label htmlFor="app-name">Icône du type</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"><span style={{ width: 16 }} ><Icon name={typeIcon} /></span></InputGroup.Text>
                                    <Form.Control
                                        placeholder="Entrez un icône"
                                        aria-describedby="basic-addon1"
                                        value={typeIcon}
                                        onChange={(event) => {
                                            setTypeIcon(event.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="d-flex flex-column">
                                <Button type="submit" variant="primary" size="sm" className='w-100'>
                                    <Icon name={formMode === "add" ? 'plus' : 'save'} className="me-2" />{formMode === "add" ? 'Ajouter' : 'Enregistrer'}
                                </Button>
                                <Button type="button" variant="secondary" size="sm" className='w-100 mt-3' onClick={() => { setFormMode(""); setEditedType(null); setFormIsOpen(false) }}>
                                    <Icon name="times" className="me-2" />Annuler
                                </Button>
                            </Form.Group>
                        </Form>
                    </>}
                </Modal.Body>

            </Modal>
        </>
    );
}

export default ModalTypes;