import Color from 'color';

export const setColors = (appColor) => {
	if (appColor === undefined) {
		return true;
	}
	const color = Color(appColor);
	const systemColor = color.hex();
	let systemColorRGB = color.rgb();
	systemColorRGB = systemColorRGB.color.join(", ");
	const systemColorDark1 = color.darken(0.15).hex();
	const systemColorDark2 = color.darken(0.3).hex();
	const systemColorDark3 = color.darken(0.45).hex();
	const systemColorDark4 = color.darken(0.6).hex();
	const systemColorLight1 = color.lighten(0.15).hex();
	const systemColorLight2 = color.lighten(0.3).hex();
	const systemColorLight3 = color.lighten(0.45).hex();
	const systemColorLight4 = color.lighten(0.6).hex();
	document.querySelector(':root').style.setProperty('--bs-primary', systemColor);
	document.querySelector(':root').style.setProperty('--bs-primary-rgb', systemColorRGB);
	document.querySelector(':root').style.setProperty('--bs-primary-rgb', systemColorRGB);
	document.querySelector(':root').style.setProperty('--bs-primary-dark1', systemColorDark1);
	document.querySelector(':root').style.setProperty('--bs-primary-dark2', systemColorDark2);
	document.querySelector(':root').style.setProperty('--bs-primary-dark3', systemColorDark3);
	document.querySelector(':root').style.setProperty('--bs-primary-dark4', systemColorDark4);
	document.querySelector(':root').style.setProperty('--bs-primary-light1', systemColorLight1);
	document.querySelector(':root').style.setProperty('--bs-primary-light2', systemColorLight2);
	document.querySelector(':root').style.setProperty('--bs-primary-light3', systemColorLight3);
	document.querySelector(':root').style.setProperty('--bs-primary-light4', systemColorLight4);

	return true;
};

export const printTimingDuration = (timings) => {

	const appLang = localStorage.getItem('appLang');

	const activeTiming = timings[timings.length - 1];
	const activeTimingStart = new Date(activeTiming.start).getTime();
	let activeTimingDuration = (Date.now() - activeTimingStart) / 1000;
	activeTimingDuration = Math.floor(activeTimingDuration);

	if (appLang === "en_US") {

		if (activeTimingDuration < 60) {
			return activeTimingDuration + " " + (activeTimingDuration > 1 ? "seconds" : "second");
		}

		// Seconds
		activeTimingDuration = Math.floor(activeTimingDuration / 60);
		if (activeTimingDuration < 60) {
			return activeTimingDuration + " " + (activeTimingDuration > 1 ? "minutes" : "minute");
		}

		// Minutes
		activeTimingDuration = Math.floor(activeTimingDuration / 60);
		if (activeTimingDuration < 24) {
			return activeTimingDuration + " " + (activeTimingDuration > 1 ? "hours" : "hour");
		}

		// Days
		activeTimingDuration = Math.floor(activeTimingDuration / 24);
		if (activeTimingDuration < 7) {
			return activeTimingDuration + " " + (activeTimingDuration > 1 ? "days" : "day");
		}

		// Weeks
		if (activeTimingDuration < 365) {
			activeTimingDuration = Math.floor(activeTimingDuration / 7);
			return activeTimingDuration + " " + (activeTimingDuration > 1 ? "weeks" : "week");
		}
		// Years
		else {
			activeTimingDuration = Math.floor(activeTimingDuration / 365);
			return activeTimingDuration + " " + (activeTimingDuration > 1 ? "years" : "year");
		}
	}

	if (activeTimingDuration < 60) {
		return activeTimingDuration + " " + (activeTimingDuration > 1 ? "secondes" : "seconde");
	}

	// Seconds
	activeTimingDuration = Math.floor(activeTimingDuration / 60);
	if (activeTimingDuration < 60) {
		return activeTimingDuration + " " + (activeTimingDuration > 1 ? "minutes" : "minute");
	}

	// Minutes
	activeTimingDuration = Math.floor(activeTimingDuration / 60);
	if (activeTimingDuration < 24) {
		return activeTimingDuration + " " + (activeTimingDuration > 1 ? "heures" : "heure");
	}

	// Days
	activeTimingDuration = Math.floor(activeTimingDuration / 24);
	if (activeTimingDuration < 7) {
		return activeTimingDuration + " " + (activeTimingDuration > 1 ? "jours" : "jour");
	}

	// Weeks
	if (activeTimingDuration < 365) {
		activeTimingDuration = Math.floor(activeTimingDuration / 7);
		return activeTimingDuration + " " + (activeTimingDuration > 1 ? "semaines" : "semaine");
	}
	// Years
	else {
		activeTimingDuration = Math.floor(activeTimingDuration / 365);
		return activeTimingDuration + " " + (activeTimingDuration > 1 ? "ans" : "an");
	}



}

export const formatDuration = (duration) => {

	// const appLang = localStorage.getItem('appLang');

	duration = Math.round(duration);
	if (duration < 60) {
		return duration + " " + (duration > 1 ? "secondes" : "seconde");
	}

	// Minutes
	// let modulo = Math.floor(duration % 60);
	duration = Math.round(duration / 60);
	if (duration < 60) {
		let result = duration + " " + (duration > 1 ? "minutes" : "minute");
		// if (modulo > 0) {
		// 	result += ', ' + modulo + " " + (modulo > 1 ? "secondes" : "seconde");
		// }
		return result;
	}

	// Hours
	duration = Math.round(duration / 60);
	if (duration < 24) {
		return duration + " " + (duration > 1 ? "heures" : "heure");
	}

	// Days
	duration = Math.round(duration / 24);
	if (duration < 7) {
		return duration + " " + (duration > 1 ? "jours" : "jour");
	}

	// Weeks
	if (duration < 365) {
		duration = Math.round(duration / 7);
		return duration + " " + (duration > 1 ? "semaines" : "semaine");
	}
	// Years
	else {
		duration = Math.round(duration / 365);
		return duration + " " + (duration > 1 ? "ans" : "an");
	}

}

export const formatDate = (date) => {
	const result = new Date(date).toLocaleDateString("fr");
	return result;
}