const API_URL = 'api.guru-worlds.com';
const PASSPORT_NAME = 'passport_tasks';

export const fetchTasks = async () => {
  const API_CALL_URL = API_URL + '/tasks';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  console.info("Tasks data", data.result);
  return data;
};

export const fetchTask = async (taskID) => {
  const API_CALL_URL = API_URL + '/task/' + taskID;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  console.info("Task data", data.result);
  return data;
};

export const fetchTypes = async () => {
  const API_CALL_URL = API_URL + '/tasks/types';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  console.info("Tasks types data", data.result);
  return data;
};

export const fetchTags = async () => {
  const API_CALL_URL = API_URL + '/tasks/tags';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  console.info("Tasks tags data", data.result);
  return data;
};

export const createTask = async (taskData) => {
  const API_CALL_URL = API_URL + '/task';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(taskData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const updateTask = async (taskID, taskData) => {
  const API_CALL_URL = API_URL + '/task/' + taskID;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(taskData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const updateSubtasksIndex = async (taskID, subtasksData) => {
  const API_CALL_URL = API_URL + '/task/' + taskID + "/subtasks";
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(subtasksData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const updateTasksIndex = async (tasksData) => {
  const API_CALL_URL = API_URL + '/tasks';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(tasksData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const deleteTask = async (taskID) => {
  const API_CALL_URL = API_URL + '/task/' + taskID;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'DELETE',
    headers: headers,
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const createSubtask = async (taskID, subtaskData) => {
  const API_CALL_URL = API_URL + '/task/' + taskID + "/subtask";
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(subtaskData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const updateSubtask = async (taskID, subtaskID, subtaskData) => {
  const API_CALL_URL = API_URL + '/task/' + taskID + '/subtask/' + subtaskID;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(subtaskData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const deleteSubtask = async (taskID, subtaskID) => {
  const API_CALL_URL = API_URL + '/task/' + taskID + '/subtask/' + subtaskID;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'DELETE',
    headers: headers,
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const createType = async (typeData) => {
  const API_CALL_URL = API_URL + '/tasks/type';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(typeData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const updateType = async (typeHandle, typeData) => {
  const API_CALL_URL = API_URL + '/tasks/type/' + typeHandle;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(typeData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const createTag = async (tagData) => {
  const API_CALL_URL = API_URL + '/tasks/tag';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(tagData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const updateTag = async (tagHandle, tagData) => {
  const API_CALL_URL = API_URL + '/tasks/tag/' + tagHandle;
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(tagData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};