
import React, { useContext } from 'react';

import { AppContext } from '../contexts/AppProvider.jsx';

function Icon({name,iconClass,className,fixedWidth}) {


  const { appThemeIconClass } = useContext(AppContext);

  if(iconClass === undefined || !iconClass) {
    iconClass = appThemeIconClass;
  }

  if(className) {
    return (
        <i className={`fa-${iconClass} fa-${name} ${className}${fixedWidth ? ' fa-fw' : ''}`}></i>
      );
  }

  return (
    <i className={`fa-${iconClass} fa-${name}${fixedWidth ? ' fa-fw' : ''}`}></i>
  );
  
}

export default Icon;


