import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import { AppContext } from '../contexts/AppProvider.jsx';

import { fetchTasks, fetchTypes } from '../services/tasks';
import { setColors } from '../libs/utils';

import AppHeader from '../components/AppHeader';
import TaskItem from '../components/TaskItem'

import { Button, ButtonGroup, Dropdown, Badge, Stack, ToastContainer, Row, Col } from 'react-bootstrap';

import Icon from '../ui/Icon';
import BasicToast from '../ui/Toast';
import PageBreadcrumb from '../ui/Breadcrumb.jsx';
import ModalTask from '../modals/ModalTask.jsx';
import ModalSubtask from '../modals/ModalSubtask.jsx';
import ModalTypes from '../modals/ModalTypes.jsx';
import BasicTooltip from '../ui/Tooltip.jsx';

function UIPage({handleToggleMenu, handleToggleRightMenu}) {

	const { t } = useTranslation();

	const [tasks, setTasks] = useState({
		todo: [],
		progress: [],
		done: []
	});
	const [types, setTypes] = useState([]);

	const [lastAction, setLastAction] = useState([]);
	const [toastAction, setToastAction] = useState([]);

	// const { themeIconClass } = useContext(ThemeContext);
	const { 
		appViewMode, 
		setAppViewMode, 
		appViewType, 
		setAppViewType, 
		appShowCondition, 
		setAppShowCondition, 
		appShowStatus, 
		setAppShowStatus, 
		appShowCreation, 
		setAppShowCreation } = useContext(AppContext);

	const [showModalTask, setShowModalTask] = useState(false);
	
	const [modeModalTask, setModeModalTask] = useState(false);
	const [showModalSubtask, setShowModalSubtask] = useState(false);
	const [showModalTypes, setShowModalTypes] = useState(false);
	const [editedTask, setEditedTask] = useState(false);

	useLayoutEffect(() => {
		setColors();
	});

	useEffect(() => {

		// Get objects list
		fetchTypes().then((response) => {
			const typesData = [];
            response.result.map((type) => {
                const result = {
                    value: type.handle,
                    label: type.title,
                    icon: type.icon.replace("fa-",""),
                    text_color: type.text_color.replace("white","black")
                }
                typesData.push(result);
            })
            setTypes(typesData);
			// setTypes(response.result);
			fetchTasks().then((response) => {
				setTasks(response.result);
				console.log(tasks);
			});
		});

	},[]);

	useEffect(() => {

		console.log(lastAction);

		// console.log(lastAction);

		// lastAction.map((action) => {
			let action = lastAction.shift();
			const newAction = (action === "create") ? <BasicToast key={Date.now()} title="Tâche créée !" message="La tâche à bien été créée" /> 
				: (action === "update_task") ? <BasicToast key={Date.now()} title="Tâche modifiéé !" message="La tâche à bien été modifiée" /> 
				: (action === "delete_task") ? <BasicToast key={Date.now()} title="Tâche suppriméé !" message="La tâche à bien été supprimée" /> 
				: (action === "start_task") ? <BasicToast key={Date.now()} title="Tâche démarrée !" message="La tâche à bien été démarrée" /> 
				: (action === "finish_task") ? <BasicToast key={Date.now()} title="Tâche terminée !" message="La tâche à bien été terminée" /> 
				: (action === "archive_task") ? <BasicToast key={Date.now()} title="Tâche archivée !" message="La tâche à bien été archivée" /> 
				: (action === "create_subtask") ? <BasicToast key={Date.now()} title="Sous-tâche créée !" message="La sous-tâche à bien été créée" /> 
				: (action === "update_subtask") ? <BasicToast key={Date.now()} title="Sous-tâche modifiée !" message="La sous-tâche à bien été modifiée" />
				: (action === "delete_subtask") ? <BasicToast key={Date.now()} title="Sous-tâche suprimée !" message="La sous-tâche à bien été supprimée" />
				: (action === "start_timing_task") ? <BasicToast key={Date.now()} title="Compteur démarré !" message="Le compteur de temps de la tâche à bien démarré" />
				: (action === "end_timing_task") ? <BasicToast key={Date.now()} title="Compteur stoppé !" message="Le compteur de temps de la tâche à bien stoppé" />
				: (action === "start_timing_subtask") ? <BasicToast key={Date.now()} title="Compteur démarré !" message="Le compteur de temps de la sous-tâche à bien démarré" />
				: (action === "end_timing_subtask") ? <BasicToast key={Date.now()} title="Compteur stoppé !" message="Le compteur de temps de la sous-tâche à bien stoppé" /> : "";
			setToastAction([...toastAction,newAction]);
		// })

		// Last action alert
		// const lastAction = localStorage.getItem("lastAction");
		// if(lastAction) {
		// 	// localStorage.removeItem("lastAction");
		// 	setToastAction(
		// 	(lastAction === "create") ? <BasicToast title="Tâche créée !" message="La tâche à bien été créée" /> 
		// 	: (lastAction === "edit") ? <BasicToast title="Tâche modifiéé !" message="La tâche à bien été modifiée" /> 
		// 	: (lastAction === "delete") ? <BasicToast title="Tâche suppriméé !" message="La tâche à bien été supprimée" /> 
		// 	: (lastAction === "create_subtask") ? <BasicToast title="Sous-tâche créée !" message="La sous-tâche à bien été créée" /> : ""
		// 	)
		// 	// setLastAction(false);
		// }

	},[lastAction]);

	// const SortableItem = sortableElement(({task, tasks, status, types, setShowModalTask, lastAction, setLastAction}) => 
	// 	<TaskItem 
	// 		task={task} 
	// 		tasks={tasks} 
	// 		status="todo" 
	// 		types={types} 
	// 		setShowModalTask={setShowModalTask} 
	// 		setModeModalTask={setModeModalTask} 
	// 		setShowModalSubtask={setShowModalSubtask} 
	// 		setEditedTask={setEditedTask} 
	// 		editedTask={editedTask} 
	// 		setTasks={setTasks} 
	// 		lastAction={lastAction} 
	// 		setLastAction={setLastAction}
	// 	/>
	// );

	// const SortableContainer = sortableContainer(({isCollapsed, children}) => {
	// 	return <div className={`UIPage`}>{children}</div>;
	// });

	const CustomToggle = React.forwardRef(({ onClick }, ref) => (
		<Button
		//   href=""
		variant="dark"
		  ref={ref}
		  onClick={(e) => {
			e.preventDefault();
			onClick(e);
		  }}
		>
		  <Icon name="chevron-down" />
		</Button>
	  ));

	return (
		<>
			<div className='App'>
				<AppHeader />
				<section className="App-section">
					<Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
						<div>
							<h2>UI</h2>
							{/* <PageBreadcrumb /> */}
						</div>

					</Stack>
					<Row>
						<Col sm={12}>
							<div className='d-flex'>
								<div style={{width:100,height:100}} className="bg-theme-lighter4">&nbsp;</div>
								<div style={{width:100,height:100}} className="bg-theme-lighter3">&nbsp;</div>
								<div style={{width:100,height:100}} className="bg-theme-lighter2">&nbsp;</div>
								<div style={{width:100,height:100}} className="bg-theme-lighter">&nbsp;</div>
								<div style={{width:100,height:100}} className="bg-theme">&nbsp;</div>
								<div style={{width:100,height:100}} className="bg-theme-darker">&nbsp;</div>
								<div style={{width:100,height:100}} className="bg-theme-darker2">&nbsp;</div>
								<div style={{width:100,height:100}} className="bg-theme-darker3">&nbsp;</div>
								<div style={{width:100,height:100}} className="bg-theme-darker4">&nbsp;</div>
							</div>
						</Col>
					</Row>
				</section>
			</div>

		</>
	);
}

export default UIPage;