import React, { useState, useEffect, useLayoutEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import ReactGA from "react-ga4";
import { askPassport, checkApplication } from './services/application';

// import GuruApp from './guru/GuruApp';

import { AppProvider } from './contexts/AppProvider';
import { TasksProvider } from './contexts/TasksProvider';

import { setColors } from './libs/utils';

import TasksList from './pages/TasksList';
import UIPage from './pages/UIPage';

import AppWrapper from './components/AppWrapper';

import MenuApp from './menus/MenuApp';
import MenuTasks from './menus/MenuTasks';

import './i18n';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-dark-5/dist/css/bootstrap-nightfall.min.css';
import "./index.scss";

function App() {

    ReactGA.initialize("G-3N449M3EV4");
    ReactGA.send("pageview");

    const [isLoading, setIsLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);

    const [originalAppName, setOriginalAppName] = useState("");
    const [originalAppLang, setOriginalAppLang] = useState("fr_FR");
    const [originalAppTheme, setOriginalAppTheme] = useState("dark");
    const [originalAppThemeColor, setOriginalAppThemeColor] = useState(localStorage.getItem('appThemeColor') ? localStorage.getItem('appThemeColor') : "#03a9f4");
    const [originalAppThemeIconClass, setOriginalAppThemeIconClass] = useState("solid");
    const [originalAppThemeTitleGoogleFont, setOriginalAppThemeTitleGoogleFont] = useState("Poppins");
    const [originalAppThemeTextGoogleFont, setOriginalAppThemeTextGoogleFont] = useState("Poppins");

    const [showedMenuApp, setShowedMenuApp] = useState(false);
    const [showedMenuTasks, setShowedMenuTasks] = useState(false);

    useLayoutEffect(() => {
        document.querySelector(':root').style.setProperty('--theme-color', originalAppThemeColor);
        setColors(originalAppThemeColor);
    }, []);

    useEffect(() => {
        askPassport().then(() => {
            checkApplication().then((response) => {
                const application = response.result;
                setOriginalAppName(application.name);
                setOriginalAppLang(application.locale);
                setOriginalAppTheme((application.theme !== undefined) ? application.theme.theme : 'dark');
                setOriginalAppThemeColor((application.theme !== undefined) ? application.theme.color : (application.system !== undefined) ? application.system.color_hex : '#03a9f4');
                setOriginalAppThemeIconClass((application.theme !== undefined) ? application.theme.icon_class : 'solid');
                setOriginalAppThemeTitleGoogleFont((application.theme !== undefined) ? application.theme.title_google_font : 'Poppins');
                setOriginalAppThemeTextGoogleFont((application.theme !== undefined) ? application.theme.text_google_font : 'Poppins');
                const currentIP = response.request.ip;
                const adminsIPs = application.admin_ips.split(",");
                setIsAdmin(adminsIPs.includes(currentIP));
                setIsLoading(false);
            });
        });
    }, []);


    if (isLoading) {
        return (
            <div className="App-loader">
                <Spinner animation="border" />
                <p className="mt-2">Chargement ...</p>
            </div>
        )
    }



    return (<>
        <AppProvider
            originalAppName={originalAppName}
            originalAppLang={originalAppLang}
            originalAppTheme={originalAppTheme}
            originalAppThemeColor={originalAppThemeColor}
            originalAppThemeIconClass={originalAppThemeIconClass}
            originalAppThemeTitleGoogleFont={originalAppThemeTitleGoogleFont}
            originalAppThemeTextGoogleFont={originalAppThemeTextGoogleFont}
            originalIsAdmin={isAdmin}
        >
            <TasksProvider
                originalTasksViewArchived={false}
            >
                <AppWrapper>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<TasksList />} />
                            <Route path="/ui" element={<UIPage />} />
                        </Routes>
                    </BrowserRouter>
                    <MenuApp />
                    <MenuTasks />
                </AppWrapper>
            </TasksProvider>
        </AppProvider>
    </>);
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

