import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
// import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { Button, ButtonGroup, Dropdown, DropdownButton, Badge, Stack, ToastContainer, Row, Col } from 'react-bootstrap';

import { fetchTasks, fetchTypes, fetchTags } from '../services/tasks';

import { AppContext } from '../contexts/AppProvider';
import { TasksContext } from '../contexts/TasksProvider';

import AppHeader from '../components/AppHeader';
import AppFooter from '../components/AppFooter';
import TaskItem from '../components/TaskItem';
import StatusTasks from '../components/StatusTasks';

import ModalTask from '../modals/ModalTask';
import ModalSubtask from '../modals/ModalSubtask';
import ModalTypes from '../modals/ModalTypes';
import ModalTags from '../modals/ModalTags';
import ModalTaskStats from '../modals/ModalTaskStats';

import Icon from '../ui/Icon';
import BasicToast from '../ui/Toast';

function TasksList() {

	const { t } = useTranslation();
	const {
		appTheme, appThemeColor,
		lastAction, setLastAction
	} = useContext(AppContext);
	const {
		tasksViewArchived, tasksTypesFilter, setTasksTypesFilter,
		showModalTask, setShowModalTask,
		modeModalTask, setModeModalTask,
		showModalTaskStats, setShowModalTaskStats,
		showModalSubtask, setShowModalSubtask,
		showModalTypes, setShowModalTypes,
		showModalTags, setShowModalTags,
		editedTask, setEditedTask,
		showedTask, setShowedTask,
		types, setTypes,
		tags, setTags,
		tasks, setTasks

	} = useContext(TasksContext);

	// const [tasks, setTasks] = useState({
	// 	todo: [],
	// 	progress: [],
	// 	done: []
	// });

	// const [lastAction, setLastAction] = useState([]);
	const [toastAction, setToastAction] = useState([]);
	const [typesUsed, setTypesUsed] = useState([]);

	useEffect(() => {
		fetchTypes().then((response) => {
			const typesData = [];
			response.result.map((type) => {
				const result = {
					value: type.handle,
					label: type.title,
					icon: type.icon.replace("fa-", ""),
					text_color: type.text_color
				}
				typesData.push(result);
			})
			console.log(typesData);
			setTypes(typesData);
			fetchTags().then((response) => {
				const tagsData = [];
				Object.keys(response.result).map((key) => {
					const tagData = {
						value: key,
						text_color: response.result[key].text_color
					}
					tagsData.push(tagData);
				})
				setTags(tagsData);
				fetchTasks().then((response) => {
					setTasks(response.result);
					const newTypesUsed = [];
					response.result.todo.forEach((task) => {
						if (tasksViewArchived || (!tasksViewArchived && task.is_archived === '0')) {
							if (!newTypesUsed.includes(task.type)) {
								newTypesUsed.push(task.type);
							}
						}
					})
					response.result.progress.forEach((task) => {
						if (tasksViewArchived || (!tasksViewArchived && task.is_archived === '0')) {
							if (!newTypesUsed.includes(task.type)) {
								newTypesUsed.push(task.type);
							}
						}
					})
					response.result.done.forEach((task) => {
						if (tasksViewArchived || (!tasksViewArchived && task.is_archived === '0')) {
							if (!newTypesUsed.includes(task.type)) {
								newTypesUsed.push(task.type);
							}
						}
					})
					setTypesUsed(newTypesUsed);
				});
			});
		});
	}, []);

	useEffect(() => {
		localStorage.setItem('tasksTypesFilter', tasksTypesFilter.join('|'));
	}, [tasksTypesFilter])

	useEffect(() => {
		const newTypesUsed = [];
		tasks.todo.forEach((task) => {
			if (tasksViewArchived || (!tasksViewArchived && task.is_archived === '0')) {
				if (!newTypesUsed.includes(task.type)) {
					newTypesUsed.push(task.type);
				}
			}
		})
		tasks.progress.forEach((task) => {
			if (tasksViewArchived || (!tasksViewArchived && task.is_archived === '0')) {
				if (!newTypesUsed.includes(task.type)) {
					newTypesUsed.push(task.type);
				}
			}
		})
		tasks.done.forEach((task) => {
			if (tasksViewArchived || (!tasksViewArchived && task.is_archived === '0')) {
				if (!newTypesUsed.includes(task.type)) {
					newTypesUsed.push(task.type);
				}
			}
		})
		setTypesUsed(newTypesUsed);
	}, [tasksViewArchived])

	useEffect(() => {
		let action = lastAction.shift();
		const newAction = (action === "create") ? <BasicToast key={Date.now()} title="Tâche créée !" message="La tâche à bien été créée" />
			: (action === "update_task") ? <BasicToast key={Date.now()} title="Tâche modifiéé !" message="La tâche à bien été modifiée" />
				: (action === "delete_task") ? <BasicToast key={Date.now()} title="Tâche suppriméé !" message="La tâche à bien été supprimée" />
					: (action === "start_task") ? <BasicToast key={Date.now()} title="Tâche démarrée !" message="La tâche à bien été démarrée" />
						: (action === "finish_task") ? <BasicToast key={Date.now()} title="Tâche terminée !" message="La tâche à bien été terminée" />
							: (action === "archive_task") ? <BasicToast key={Date.now()} title="Tâche archivée !" message="La tâche à bien été archivée" />
								: (action === "create_subtask") ? <BasicToast key={Date.now()} title="Sous-tâche créée !" message="La sous-tâche à bien été créée" />
									: (action === "update_subtask") ? <BasicToast key={Date.now()} title="Sous-tâche modifiée !" message="La sous-tâche à bien été modifiée" />
										: (action === "delete_subtask") ? <BasicToast key={Date.now()} title="Sous-tâche suprimée !" message="La sous-tâche à bien été supprimée" />
											: (action === "start_timing_task") ? <BasicToast key={Date.now()} title="Compteur démarré !" message="Le compteur de temps de la tâche à bien été démarré" />
												: (action === "end_timing_task") ? <BasicToast key={Date.now()} title="Compteur stoppé !" message="Le compteur de temps de la tâche à bien été stoppé" />
													: (action === "start_timing_subtask") ? <BasicToast key={Date.now()} title="Compteur démarré !" message="Le compteur de temps de la sous-tâche à bien été démarré" />
														: (action === "end_timing_subtask") ? <BasicToast key={Date.now()} title="Compteur stoppé !" message="Le compteur de temps de la sous-tâche à bien été stoppé" />
															: (action === "start_subtask") ? <BasicToast key={Date.now()} title="Sous-tâche démarrée !" message="La sous-tâche à bien été démarrée" />
																: (action === "stop_subtask") ? <BasicToast key={Date.now()} title="Sous-tâche stoppée !" message="La sous-tâche à bien été stoppée" /> : "";
		setToastAction([...toastAction, newAction]);
	}, [lastAction]);

	const CustomToggle = React.forwardRef(({ onClick }, ref) => (
		<Button variant={appTheme === 'dark' ? 'outline-primary' : 'outline-primary'} ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} style={{ borderRadius: '0 .375rem .375rem 0' }}>
			<Icon name="chevron-down" className="text-primary" />
		</Button>
	));

	const CustomToggle2 = React.forwardRef(({ onClick }, ref) => (
		<Button variant={'outline-secondary'} ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} style={{ borderRadius: '.375rem' }}>
			Filtrer par type<Icon name="chevron-down" className="ms-2" />
		</Button>
	));

	return (
		<>
			<Helmet>
				<meta name="theme-color" content={appThemeColor} />
			</Helmet>
			<div className='App'>
				<AppHeader isHome />
				<section className="App-section">
					<Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
						<div>
							<h2>{t('page_title_tasks_list')}</h2>
						</div>
						<div className="ms-sm-auto d-flex d-sm-block flex-column-reverse">
							<Dropdown as={ButtonGroup} className="me-3 mt-3 mt-sm-0" autoClose="">

								<Dropdown.Toggle as={CustomToggle2} split id="dropdown-split-ba2sic" />
								<Dropdown.Menu variant={appTheme === 'dark' ? 'dark' : ''}>
									{typesUsed.includes("tache") && <Dropdown.Item
										onClick={(e) => { e.preventDefault(); setTasksTypesFilter(tasksTypesFilter.includes('tache') ? tasksTypesFilter.filter((item) => item !== 'tache') : [...tasksTypesFilter, 'tache']); }}
										className={`px-2 d-flex text-default`}
									>
										<Icon name='check' fixedWidth className={`me-2 mt-1 text-default`} />
										Tâche
										{tasksTypesFilter.includes('tache') ?
											<span className="ms-auto">
												<Icon name="check" className="text-default mt-1 ms-2" />
											</span>
											:
											<span className="ms-auto" style={{ width: 24 }}>
												&nbsp;
											</span>
										}
									</Dropdown.Item>}
									{types.filter((type) => typesUsed.includes(type.value)).filter((type) => type.value !== 'tache').map((type) => (
										<Dropdown.Item
											key={type.value}
											onClick={(e) => { e.preventDefault(); setTasksTypesFilter(tasksTypesFilter.includes(type.value) ? tasksTypesFilter.filter((item) => item !== type.value) : [...tasksTypesFilter, type.value]); }}
											className={`px-2 d-flex text-${type.text_color}`}
										>
											<Icon name={type.icon} fixedWidth className={`me-2 mt-1 text-${type.text_color}`} />
											{type.label}
											{tasksTypesFilter.includes(type.value) ?
												<span className="ms-auto">
													<Icon name="check" className="text-default mt-1 ms-2" />
												</span>
												:
												<span className="ms-auto" style={{ width: 24 }}>
													&nbsp;
												</span>
											}
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
							<Dropdown as={ButtonGroup}>
								<Button variant="primary" onClick={() => { setModeModalTask("add"); setShowModalTask(true); }}>
									<Icon name="plus" className="me-2" />
									{t('create_task')}
								</Button>
								<Dropdown.Toggle as={CustomToggle} split id="dropdown-split-basic" />
								<Dropdown.Menu variant={appTheme === 'dark' ? 'dark' : ''}>
									<Dropdown.Item onClick={() => setShowModalTypes(true)}>{t('tasks_types')}</Dropdown.Item>
									<Dropdown.Item onClick={() => setShowModalTags(true)}>{t('tasks_tags')}</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</Stack>
					<Row>
						<StatusTasks status="todo" />
						<StatusTasks status="progress" />
						<StatusTasks status="done" />
					</Row>
				</section>
				<AppFooter />
			</div>
			<ModalTask />
			<ModalSubtask />
			<ModalTypes />
			<ModalTags />
			<ModalTaskStats />
			<ToastContainer position="bottom-end" style={{ paddingBottom: 20, paddingRight: 20 }}>
				{toastAction}
			</ToastContainer>
		</>
	);
}

export default TasksList;