import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import Select from 'react-select';
import selectStyles from '../ui/selectStyles';
import { CustomOption, CustomSingleValue } from '../ui/selectComponents';
import { Typeahead, Token } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

import { AppContext } from '../contexts/AppProvider';
import { TasksContext } from '../contexts/TasksProvider';
import ReactGA from "react-ga4";

import { createTask, updateTask, deleteTask, fetchTasks, fetchTask, fetchTags } from '../services/tasks';

import Icon from '../ui/Icon';

function ModalTask() {

    const { t } = useTranslation();

    const {
        appTheme,
        lastAction, setLastAction
    } = useContext(AppContext);

    const {
        showModalTask, setShowModalTask,
        modeModalTask, editedTask,
        types, tags,
        setTasks
    } = useContext(TasksContext);

    const [taskTitle, setTaskTitle] = useState("");
    const [taskDescription, setTaskDescription] = useState("");
    const [taskStatus, setTaskStatus] = useState("todo");
    const [taskType, setTaskType] = useState("");
    const [taskTags, setTaskTags] = useState([]);

    const statusOptions = [
        { value: 'todo', label: t('task_status_to_do'), icon: 'calendar-day' },
        { value: 'progress', label: t('task_status_in_progress'), icon: 'hourglass-clock' },
        { value: 'done', label: t('task_status_done'), icon: 'flag-checkered' },
    ];
    const [tagsOptions, setTagsOptions] = useState([]);
    const [typesOptions, setTypesOptions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
    const [selectedType, setSelectedType] = useState({});

    useEffect(() => {
        if (modeModalTask === "edit") {
            fetchTask(editedTask).then((response) => {
                if (response.result) {
                    const task = response.result;
                    setTaskTitle(task.title);
                    setTaskDescription(task.description);
                    setTaskStatus(task.status);
                    setTaskType(task.type);
                    setTaskTags(task.tags !== ',' && task.tags !== '' ? task.tags.split(",") : []);
                    if (task.status === "todo") {
                        setSelectedStatus(statusOptions[0])
                    }
                    if (task.status === "progress") {
                        setSelectedStatus(statusOptions[1])
                    }
                    if (task.status === "done") {
                        setSelectedStatus(statusOptions[2])
                    }
                    setSelectedType(types.filter((type) => (type.value === task.type)));
                    const tagsList = [];
                    tags.forEach((tag) => tagsList.push(tag.value));
                    setTagsOptions(tagsList);
                    setTypesOptions(types);
                }
            });
        }
        else {
            setTaskTitle("");
            setTaskDescription("");
            setTaskStatus("todo");
            setTaskType("tache");
            setTaskTags([]);
            setSelectedStatus(statusOptions[0]);
            setSelectedType(types.filter((item) => item.value === "tache").shift());
            const tagsList = [];
            tags.forEach((tag) => tagsList.push(tag.value));
            setTagsOptions(tagsList);
            setTypesOptions(types);
        }
        console.log(types);
    }, [showModalTask]);




    const handleSubmit = (event) => {
        event.preventDefault();
        // setLastAction("");
        const taskData = {
            task_title: taskTitle,
            task_description: taskDescription,
            task_status: taskStatus,
            task_type: taskType,
            task_tags: taskTags.join(",")
        }

        if (modeModalTask === "add") {
            createTask(taskData).then((response) => {
                if (response.status === "Created") {
                    console.log("✅ Task created");
                    setShowModalTask(false);
                    setLastAction([...lastAction, "create"]);
                    ReactGA.event({
                        action: "add_task"
                    })
                    fetchTasks().then((response) => {
                        setTasks(response.result);
                    });
                }
            });
        }

        if (modeModalTask === "edit" && editedTask) {
            updateTask(editedTask, taskData).then((response) => {
                if (response.status === "Updated") {
                    console.log("✅ Task updated");
                    setShowModalTask(false);
                    setLastAction([...lastAction, "update"]);
                    fetchTasks().then((response) => {
                        setTasks(response.result);
                    });
                }
            });
        }
    }

    const handleDelete = (event) => {
        event.preventDefault();
        // setLastAction("");
        if (editedTask) {
            deleteTask(editedTask).then((response) => {
                if (response.status === "Deleted") {
                    console.log("✅ Task deleted");
                    setShowModalTask(false);
                    setLastAction([...lastAction, "delete"]);
                    fetchTasks().then((response) => {
                        setTasks(response.result);
                    });
                }
            });
        }
    }

    const selectStatus = (option) => {
        setSelectedStatus(option);
        setTaskStatus(option.value);
    }

    const selectType = (option) => {
        setSelectedType(option);
        setTaskType(option.value);
    }

    return (
        <>

            <Modal show={showModalTask} onHide={() => setShowModalTask(false)} centered>

                <Modal.Header closeButton>
                    <Modal.Title className={`text-${appTheme === "dark" ? "black" : 'white'}`}>{modeModalTask === "add" && t('create_task')}{modeModalTask === "edit" && t('edit_task')}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body className={`bg-${appTheme === "dark" ? 'black' : 'white'} text-${appTheme !== "dark" ? 'black' : 'white'}`}>

                        <Form.Group className="mb-3">
                            <Form.Label>Titre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Entrez le titre de la tâche"
                                value={taskTitle}
                                onChange={(event) => setTaskTitle(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Entrez la description de la tâche"
                                value={taskDescription}
                                onChange={(event) => setTaskDescription(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Statut</Form.Label>
                            <Select
                                options={statusOptions}
                                value={selectedStatus}
                                onChange={selectStatus}
                                styles={selectStyles}
                                components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Type</Form.Label>
                            <Select
                                options={types}
                                styles={selectStyles}
                                value={selectedType}
                                onChange={selectType}
                                components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Labels</Form.Label>
                            <Typeahead
                                id="labels-typeahead"
                                allowNew multiple
                                placeholder={`Entrez les labels de la tâche`}
                                selected={taskTags}
                                options={tagsOptions}
                                onChange={(event) => {
                                    const results = [];
                                    event.forEach((item) => {
                                        if (typeof item === 'string') {
                                            results.push(item);
                                        }
                                        else {
                                            results.push(item.label);
                                        }
                                    })

                                    setTaskTags(results);
                                }}
                                renderMenuItemChildren={(option, props, index) => {
                                    const textColor = tags.filter((tag) => tag.value === option).shift().text_color;
                                    return (
                                        <span className={`text-${textColor}`}>{option}</span>
                                    );
                                }}
                                renderToken={(option, props, idx) => {
                                    const textColor = tags.filter((tag) => tag.value === option).shift().text_color;
                                    return (
                                        <Token key={idx} className={`text-${textColor} border-${textColor}`} onRemove={() => {
                                            const newTaskTags = taskTags.filter((tag) => tag !== option);
                                            setTaskTags(newTaskTags);
                                        }}>
                                            {option}

                                        </Token>
                                    );
                                }}
                                newSelectionPrefix='Nouvel élément : '
                            />
                        </Form.Group>
                        {modeModalTask === "eddit" && editedTask &&
                            <Form.Group className="mb-3 mt-5 text-center">
                                <Button type="button" variant="danger" size="sm" onClick={handleDelete}>
                                    <Icon name="trash" className="me-2" />Supprimer la tâche
                                </Button>
                            </Form.Group>
                        }
                    </Modal.Body>
                    <Modal.Footer className={`bg-${appTheme === "dark" ? 'dark' : 'white'} border-${appTheme === "dark" ? 'black' : 'gray-300'}`} style={{ borderTopWidth: 1, borderTopStyle: "solid" }}>
                        <div className="d-grid w-100">
                            <Button type="submit" variant="primary" size="lg">
                                <Icon name="check" className="me-2" />{modeModalTask === "add" && t('add')}{modeModalTask === "edit" && t('save')}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ModalTask;