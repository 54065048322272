import { useState, createContext } from 'react';

export const TasksContext = createContext();

export const TasksProvider = ({ originalTasksViewArchived, children }) => {

    const [tasksViewArchived, setTasksViewArchived] = useState(originalTasksViewArchived);
    const [tasksTypesFilter, setTasksTypesFilter] = useState(localStorage.getItem('tasksTypesFilter') ? localStorage.getItem('tasksTypesFilter').split("|") : []);

    const [showModalTask, setShowModalTask] = useState(false);
    const [modeModalTask, setModeModalTask] = useState(false);
    const [showModalTaskStats, setShowModalTaskStats] = useState(false);
    const [showModalSubtask, setShowModalSubtask] = useState(false);
    const [showModalTypes, setShowModalTypes] = useState(false);
    const [showModalTags, setShowModalTags] = useState(false);

    const [editedTask, setEditedTask] = useState(false);
    const [showedTask, setShowedTask] = useState(false);

    const [showedMenuTasks, setShowedMenuTasks] = useState(false);

    const [types, setTypes] = useState([]);
    const [tags, setTags] = useState([]);

    const [tasks, setTasks] = useState({
        todo: [],
        progress: [],
        done: []
    });

    return (
        <TasksContext.Provider value={{
            tasksViewArchived, setTasksViewArchived,
            tasksTypesFilter, setTasksTypesFilter,
            showModalTask, setShowModalTask,
            modeModalTask, setModeModalTask,
            showModalTaskStats, setShowModalTaskStats,
            showModalSubtask, setShowModalSubtask,
            showModalTypes, setShowModalTypes,
            showModalTags, setShowModalTags,
            showedMenuTasks, setShowedMenuTasks,
            editedTask, setEditedTask,
            showedTask, setShowedTask,
            types, setTypes,
            tags, setTags,
            tasks, setTasks
        }}>
            {children}
        </TasksContext.Provider>
    )
}