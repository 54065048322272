import React, { useState, useContext } from 'react';
import Toast from 'react-bootstrap/Toast';

import { AppContext } from '../contexts/AppProvider.jsx';

import Icon from './Icon';



function BasicToast(props) {
  const { appTheme } = useContext(AppContext);
  const [show, setShow] = useState(true);
  return (
    <Toast onClose={() => setShow(false)} show={show} delay={10000} autohide bg={appTheme === "dark" ? "dark" : ""}>
      <Toast.Header>
        {/* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
        <strong className="me-auto">{props.title}</strong>
        {/* <small className="text-muted">just now</small> */}
      </Toast.Header>
      <Toast.Body>
        <Icon name="check" className="text-success me-3 fw-900" style={{fontSize:'1.2em'}} />
        {props.message}
      </Toast.Body>
    </Toast>
  );
}

export default BasicToast;