import React from 'react';
import { useTranslation } from 'react-i18next';

function AppFooter() {

    const { t, i18n } = useTranslation();

    return (
        <footer className='App-footer'>
            <p className='text-center mb-0'>Made by Guru Worlds</p>
        </footer>
    )

}

export default AppFooter;