const API_URL = 'api.guru-worlds.com';
const PASSPORT_NAME = 'passport_tasks';

export const checkApplication = async () => {
  const API_CALL_URL = API_URL + '/authority';
  var formData = new FormData();
  formData.append('action', 'checkApplication');
  const options = {
    method: 'POST',
    body: formData
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  console.info("Application data", data.result);
  return data;
};

export const askPassport = async () => {
  if (localStorage.getItem(PASSPORT_NAME)) {
    return localStorage.getItem(PASSPORT_NAME);
  }
  const API_CALL_URL = API_URL + '/authority';
  var formData = new FormData();
  formData.append('action', 'askPassport');
  const options = {
    method: 'POST',
    body: formData
  };
  const data = await fetch('https://' + API_CALL_URL, options);
  const passportData = await data.json();
  const passport = passportData.result;
  localStorage.setItem(PASSPORT_NAME, passport);
  return passport;
};

export const updateApplication = async (appData) => {
  const API_CALL_URL = API_URL + '/application';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(appData)
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const fetchGoogleFonts = async () => {
  const API_CALL_URL = API_URL + "/data/googlefonts";
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append("Authorization", "Bearer " + passport);
  const options = {
    method: "GET",
    headers: headers,
  };
  const response = await fetch("https://" + API_CALL_URL, options);
  const data = await response.json();
  // console.info("Google Fonts data", data.result);
  return data;
};