import React, { useState, useContext } from 'react';

import { createTag, updateTag, fetchTags, fetchTasks } from '../services/tasks';
import { setColors } from '../libs/utils';
import { Button, Modal, Form, Table, InputGroup } from 'react-bootstrap';

import { AppContext } from '../contexts/AppProvider';
import { TasksContext } from '../contexts/TasksProvider';

import Select, { components } from 'react-select';
import selectStyles from '../ui/selectStyles';
import { CustomOption, CustomSingleValue } from '../ui/selectComponents';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

import Icon from '../ui/Icon';
import { useEffect } from 'react';

const textColorsOptions = [
    { value: 'default', label: 'Noir/Blanc', text_color: 'default' },
    { value: 'primary', label: 'Principale', text_color: 'success' },
    { value: 'secondary', label: 'Secondaire', text_color: 'secondary' },
    { value: 'blue', label: 'Bleu', text_color: 'blue' },
    { value: 'purple', label: 'Violet', text_color: 'purple' },
    { value: 'green', label: 'Vert', text_color: 'green' },
    { value: 'orange', label: 'Orange', text_color: 'orange' },
    { value: 'red', label: 'Rouge', text_color: 'red' },
    { value: 'yellow', label: 'Jaune', text_color: 'yellow' },
];

function ModalTags() {

    const { appTheme } = useContext(AppContext);

    const {
        showModalTags, setShowModalTags,
        tags, setTags,
        setTasks
    } = useContext(TasksContext);

    const [tagName, setTagName] = useState("");
    const [tagTextColor, setTagTextColor] = useState("default");

    const [selectedTextColor, setSelectedTextColor] = useState(textColorsOptions[0]);

    const [formIsOpen, setFormIsOpen] = useState(false);
    const [formMode, setFormMode] = useState("add");
    const [editedTag, setEditedTag] = useState(null);

    useEffect(() => {
        setFormIsOpen(false);
    }, [showModalTags])

    useEffect(() => {
        if (editedTag && formMode === "edit") {
            const tag = tags.filter((item) => item.value === editedTag).shift();
            console.log("t", tag.text_color);
            console.log("e", editedTag);
            setTagName(tag.value);
            setTagTextColor(tag.text_color);
            setSelectedTextColor(textColorsOptions.filter((item) => item.value === tag.text_color).shift());
        }
    }, [editedTag]);

    useEffect(() => {
        if (!formIsOpen) {
            // const type = types.filter((item) => item.value === editedType).shift();
            // console.log(type);
            setTagName("");
            setTagTextColor("default");
            setSelectedTextColor(textColorsOptions[0]);
        }
    }, [formIsOpen]);

    const handleSubmit = (event) => {
        event.preventDefault();
        // setLastAction("");
        const tagData = {
            tag_name: tagName,
            tag_text_color: tagTextColor,
        }

        if (formMode === "add") {
            createTag(tagData).then((response) => {
                if (response.status === "Created") {
                    console.log("✅ Tag created");
                    // handleClose();
                    // setLastAction([...lastAction,"create"]);
                    fetchTags().then((response) => {
                        const tagsData = [];
                        Object.keys(response.result).map((key) => {
                            const tagData = {
                                value: key,
                                text_color: response.result[key].text_color
                            }
                            tagsData.push(tagData);
                        })
                        setTags(tagsData);
                        fetchTasks().then((response) => {
                            setTags(tagsData);
                            setTasks(response.result);

                            setFormIsOpen(false);
                        });
                    });
                }
            });
        }

        if (formMode === "edit") {
            updateTag(editedTag, tagData).then((response) => {
                if (response.status === "Updated") {
                    console.log("✅ Tag updated");
                    // handleClose();
                    // setLastAction([...lastAction,"create"]);
                    fetchTags().then((response) => {
                        const tagsData = [];
                        Object.keys(response.result).map((key) => {
                            const tagData = {
                                value: key,
                                text_color: response.result[key].text_color
                            }
                            tagsData.push(tagData);
                        })
                        setTags(tagsData);
                        fetchTasks().then((response) => {
                            setTags(tagsData);
                            setTasks(response.result);

                            setFormIsOpen(false);
                        });

                        // setFormIsOpen(false);
                    });
                }
            });
        }



    }


    return (
        <>

            <Modal show={showModalTags} onHide={() => setShowModalTags(false)} centered>

                <Modal.Header closeButton>
                    <Modal.Title className={`text-${appTheme === "dark" ? "black" : 'white'}`}>Gestion des labels</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`bg-${appTheme === "dark" ? 'black' : 'white'} text-${appTheme !== "dark" ? 'black' : 'white'}`}>
                    {!formIsOpen && <>
                        <div className='h6 text-center text-theme fw-400'>Liste des labels</div>
                        <Table bordered variant={appTheme === "dark" ? "dark" : ''}>
                            {/* <thead>
                            <tr>
                                <th>Types</th>
                            </tr>
                        </thead> */}
                            <tbody>
                                {tags.map((tag) => {
                                    return (
                                        <tr key={tag.value}>
                                            <td className={`text-${tag.text_color !== "black" ? tag.text_color : 'white'}`} style={{ lineHeight: '30px' }}>
                                                <div className='d-flex'>
                                                    <span>
                                                        {tag.value}
                                                    </span>
                                                    <Button
                                                        variant='outline-primary'
                                                        size="sm"
                                                        className='ms-auto'
                                                        onClick={() => { setFormMode("edit"); setEditedTag(tag.value); setFormIsOpen(true); }}
                                                    ><Icon name="pencil" className="me-2" />Modifier</Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <div className='d-flex'>
                            <Button variant="outline-primary" size="sm" className='w-100' onClick={() => { setFormMode("add"); setEditedTag(null); setFormIsOpen(true) }}><Icon name="plus" className="me-2" />Nouveau label</Button>
                        </div>
                    </>}
                    {formIsOpen && <>
                        <div className='h6 text-center text-theme fw-400'>{formMode === "add" ? 'Nouveau label' : 'Modifier le label'}</div>
                        <Form onSubmit={handleSubmit}>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="app-name">Nom du tag</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="tag-name"
                                    placeholder="Entrez un nom"
                                    value={tagName}
                                    onChange={(event) => {
                                        setTagName(event.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Couleur du texte</Form.Label>
                                <Select
                                    options={textColorsOptions}
                                    styles={selectStyles}
                                    value={selectedTextColor}
                                    onChange={(option) => { setSelectedTextColor(option); setTagTextColor(option.value); }}
                                    components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                                />
                            </Form.Group>
                            <Form.Group className="d-flex flex-column">
                                <Button type="submit" variant="primary" size="sm" className='w-100'>
                                    <Icon name={formMode === "add" ? 'plus' : 'save'} className="me-2" />{formMode === "add" ? 'Ajouter' : 'Enregistrer'}
                                </Button>
                                <Button type="button" variant="secondary" size="sm" className='w-100 mt-3' onClick={() => { setFormMode(""); setEditedTag(null); setFormIsOpen(false) }}>
                                    <Icon name="times" className="me-2" />Annuler
                                </Button>
                            </Form.Group>
                        </Form>
                    </>}
                </Modal.Body>

            </Modal>
        </>
    );
}

export default ModalTags;